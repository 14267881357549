import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryFilter',
  pure: false
})
export class CategoryFilterPipe implements PipeTransform {
  transform(categories: any[], id: string): any {
    const item = categories?.find(obj => obj.id === id);
    return item ? (item.subCategories || []) : [];
  }
}