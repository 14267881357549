import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '@app/shared/services/navbar.service';
import { ICompanySetting } from '@shared/interfaces/company-setting.interface';
import { IEvent } from '@shared/interfaces/event.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CompanySettingsService } from '@shared/services/company-settings.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { PaginationEventsService } from '@shared/services/pagination/pagination.events.service';
import jwtDecode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @LocalStorage() private companySettings: ICompanySetting;

  public settingsSubscription: Subscription;
  public settings: ICompanySetting;

  public isProduction = Boolean(this._commonEnvironments.IS_PRODUCTION);
  public isCollapsed = Boolean(true);
  public isAdmin = Boolean(false);
  public isTenant = Boolean(false);
  public isAnalytics = Boolean(false);
  public isManager = Boolean(false);
  public isEmployee = Boolean(false);
  public isExpanded = Boolean(true);

  public logo = String(
    `../../../../assets/images/logo_onsitehq.png`
  );
  public logoMenu = String(
    `../../../../assets/images/logo_onsite_white.png`
  );
  public logoWhiteMenu = String(
    `../../../../assets/images/logo_white.png`
  );

  public openEventsModal: Subject<IEvent> = new Subject();

  public params: any = {
    environment: this._commonEnvironments.ENVIRONMENT
  };

  public links: {
    id: number;
    isEnabled: boolean;
    items: {
      label: string;
      icon: string;
      url: string;
      isEnabled: boolean;
      order: number;
    }[];
  }[] = [];

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _navbar: NavbarService,
    private readonly _router: Router,
    private readonly _paginationEvents: PaginationEventsService
  ) {
    this.afterSubmit = this.afterSubmit.bind(this);

    this.settingsSubscription = this._navbar
      .getCompanySettings()
      .subscribe((res: { label: string; value: string }) => {
        const label = res.label;

        switch (label) {
          // ATS
          case 'isQrCodeEnabled':
            if (res.value) {
              const isQrCodeEnabled = this.links[1].items.find(
                (l: any) => l.label === 'NAVBAR.LINKS.QR_CODES'
              );

              if (!isQrCodeEnabled) {
                this.setQRCodesLink();
              }
            } else if (!this.companySettings.isQrCodeEnabled) {
              this.links[1].items = this.links[1].items.filter(
                (l: any) => l.label !== 'NAVBAR.LINKS.QR_CODES'
              );
            }
            break;
          case 'isReportEnabled':
        }

        this.sortItems();
      });
  }

  ngOnInit(): void {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());

    this.isAdmin = Boolean(payload.employeeRole === 0);
    this.isManager = Boolean(payload.employeeRole === 1);
    this.isAnalytics = Boolean(payload.employeeRole === 2);
    this.isEmployee = Boolean(payload.employeeRole === 3);
    this.isTenant = Boolean(payload.role === 5);
    this.settings = this.companySettings;
    this.setLinks();
  }

  ngOnDestroy(): void {
    this.settingsSubscription?.unsubscribe();
  }

  public trackByFn(i): string {
    return i.id;
  }

  public openModal() {
    this.openEventsModal.next();
  }

  public afterSubmit(e: IEvent) {
    this._paginationEvents.reloadPage(1);
    this._router.navigate(['/events', e.id]);
  }

  public expandContent() {
    if (this.isExpanded) {
      document.getElementById('protected-content').classList.add('expand-content');
    } else {
      document.getElementById('protected-content').classList.remove('expand-content');
    }
    this.isExpanded = !this.isExpanded;
  }

  private setLinks() {
    if (this.isTenant) {
      this.links = [
        {
          id: 0,
          isEnabled: true,
          items: [
            {
              label: 'NAVBAR.LINKS.TASKS',
              icon: 'lnr-warning',
              url: '/tasks',
              isEnabled: true,
              order: 4
            }
          ]
        }
      ];
    } else {
      this.links = [
        {
          id: 0,
          isEnabled: true,
          items: [
            {
              label: 'NAVBAR.LINKS.DASHBOARD',
              icon: 'lnr-chart-bars',
              url: '/dashboard/events',
              isEnabled: true,
              order: 0
            },
            // {
            //   label: 'NAVBAR.LINKS.THREADS',
            //   url: '/threads',
            //   icon: 'lnr-envelope',
            //   isEnabled: true,
            //   order: 1
            // },
            // {
            //   label: 'NAVBAR.LINKS.INSPECTIONS',
            //   icon: 'lnr-calendar-full',
            //   url: '/events',
            //   isEnabled: true,
            //   order: 2
            // },
            {
              label: 'NAVBAR.LINKS.REPORTS',
              icon: 'lnr-chart-bars',
              url: '/reports',
              isEnabled: (this.settings.isReportEnabled ? true : false) && (this.isAdmin || this.isManager),
              order: 3
            },
            {
              label: 'NAVBAR.LINKS.CHECKLISTS',
              icon: 'lnr-clipboard-check',
              url: '/checklists',
              isEnabled: true,
              order: 4
            },
            {
              label: 'NAVBAR.LINKS.TASKS',
              icon: 'lnr-warning',
              url: '/tasks',
              isEnabled: true,
              order: 5
            },
            {
              label: 'NAVBAR.LINKS.CONTACTS',
              icon: 'lnr-contacts',
              url: '/contacts',
              isEnabled: true,
              order: 6
            },
            {
              label: 'NAVBAR.LINKS.ASSETS',
              url: '/assets',
              icon: 'lnr-pie-chart',
              isEnabled: true,
              order: 7
            },
            {
              label: 'NAVBAR.LINKS.ISSUES',
              url: '/issues',
              icon: 'lnr-cross-circle',
              isEnabled: true,
              order: 8
            },

            // {
            //   label: 'NAVBAR.LINKS.CALENDAR',
            //   url: '/calendar',
            //   icon: 'lnr-calendar-full',
            //   isEnabled: true,
            //   order: 9
            // },
            // {
            //   label: 'NAVBAR.LINKS.ACTIVITIES',
            //   icon: 'lnr-radar',
            //   url: '/activities',
            //   isEnabled: this.isManager
            // }
          ]
        },
        {
          id: 1,
          isEnabled: this.isAdmin || this.isManager,
          items: [
            {
              label: 'NAVBAR.LINKS.EMAILS',
              icon: 'lnr-envelope',
              url: '/emails',
              isEnabled: true,
              order: 1
            },
            {
              label: 'NAVBAR.LINKS.LOCATIONS',
              icon: 'lnr-location',
              url: '/locations',
              isEnabled: true,
              order: 2
            },
            {
              label: 'NAVBAR.LINKS.TEMPLATES',
              icon: 'lnr-document2',
              url: '/templates',
              isEnabled: true,
              order: 3
            },
            {
              label: 'NAVBAR.LINKS.USERS',
              icon: 'lnr-users',
              url: '/users',
              isEnabled: true,
              order: 4
            },
            {
              label: 'NAVBAR.LINKS.LIBRARIES',
              url: '/documents',
              icon: 'lnr-cloud-upload',
              isEnabled: true,
              order: 5
            },
            {
              label: 'NAVBAR.LINKS.MARKETPLACE',
              icon: 'lnr-store',
              url: '/marketplace',
              isEnabled: this.isAdmin,
              order: 6
            },
            {
              label: 'NAVBAR.LINKS.SETTINGS',
              icon: 'lnr-cog',
              url: '/settings',
              isEnabled: this.isAdmin,
              order: 7
            },
          ]
        }
      ];
    }

    if (this.companySettings?.isQrCodeEnabled) {
      this.setQRCodesLink();
    }

    this.sortItems();
  }

  private setQRCodesLink() {
    if (!this.isTenant) {
      this.links[1].items.push({
        label: 'QR codes',
        url: '/qrcodes',
        icon: 'lnr-focus',
        isEnabled: true,
        order: 2
      });
    }
  }

  private sortItems() {
    this.links.map((l: any) => {
      l.items.sort((i, ii) => i.order - ii.order);
    });
  }
}
