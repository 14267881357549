import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getTimezoneShort } from '@shared/helpers/timezone.helper';
import { ITask } from '@shared/interfaces/task.interface';
import moment from 'moment';
export interface ActiveCommentInterface {
  id: string;
  type: ActiveCommentTypeEnum;
}
export enum ActiveCommentTypeEnum {
  replying = 'replying',
  editing = 'editing',
}

export interface CommentInterface {
  id: string;
  body: string;
  username: string;
  userId: string;
  parentId: null | string;
  createdAt: string;
  taskId: string | any;
  task?: ITask;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'thread-comment',
  templateUrl: './thread-comment.component.html'
})
export class ThreadCommentComponent {
  @Input() comment: CommentInterface;
  @Input() activeComment!: ActiveCommentInterface | null;
  @Input() replies!: CommentInterface[];
  @Input() currentUserId!: string;
  @Input() parentId!: string | null;
  @Input() timezone: string;

  @Output()
  setActiveComment = new EventEmitter<ActiveCommentInterface | null>();
  @Output()
  deleteComment = new EventEmitter<string>();
  @Output()
  addComment = new EventEmitter<{ text: string; parentId: string | null; taskId: string }>();
  @Output()
  updateComment = new EventEmitter<{ text: string; commentId: string }>();

  createdAt = String('');
  createdAtTimezone = String('');
  canReply = Boolean(false);
  canEdit = Boolean(false);
  canDelete = Boolean(false);
  activeCommentType = ActiveCommentTypeEnum;
  replyId: string | null = null;

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    const fiveMinutes = 300000;
    const timePassed =
      new Date().getMilliseconds() -
        new Date(this.comment.createdAt).getMilliseconds() >
      fiveMinutes;
    this.createdAt = this.comment.createdAt;
    this.createdAtTimezone = ', '+ moment(this.comment.createdAt).tz(this.timezone).format('lll') + ' ' + getTimezoneShort(this.timezone);
    this.canReply = Boolean(this.currentUserId);
    this.canEdit = this.currentUserId === this.comment.userId && !timePassed;
    this.canDelete =
      this.currentUserId === this.comment.userId &&
      this.replies.length === 0 &&
      !timePassed;
    this.replyId = this.parentId ? this.parentId : this.comment.id;
  }

  isReplying(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === this.activeCommentType.replying
    );
  }

  isEditing(): boolean {
    // return true;
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === 'editing'
    );
  }
}
