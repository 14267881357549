import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { environment } from '@environment/environment';
import { FullCalendarModule } from '@fullcalendar/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [AppComponent],
  imports: [
    InfiniteScrollModule,
    CoreModule,
    FullCalendarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.IS_PRODUCTION,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
