import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IDocument } from '@shared/interfaces/document.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { Picture } from '@shared/models/picture.model';
import { PicturesSerializer } from '@shared/serializers/pictures.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class PicturesService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/pictures`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) { }

  public delete(id: string): Observable<IPicture> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Picture(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IPicture> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Picture(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public findByOwnerId(id: string): Observable<IPicture> {
    return this._http.get(`${this.endpoint}/location/${id}`).pipe(
      map((res: any) => new Picture(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public findLocationPicture(id: string): Observable<IPicture> {
    return this._http.get(`${this.endpoint}/location/picture/${id}`).pipe(
      map((res: any) => new Picture(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IPicture[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((e: IPicture) => new Picture(e))
      ),
      catchError(errorHandler)
    );
  }

  public getPictures(ids: string): Observable<IPicture[]> {
    return this._http.get(`${this.endpoint}/issues/pictures?picturesIds=${ids}`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((d: IPicture) => new Picture(d))
      ),
      catchError(errorHandler)
    );
  }

  public patch(p: Partial<IPicture>): Observable<IPicture> {
    const data = PicturesSerializer.serialize(p);
    return this._http.patch(`${this.endpoint}/${p.id}`, data).pipe(
      map((res: any) => new Picture(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(filters, blob: Blob): Observable<IPicture> {
    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    const formData: FormData = new FormData();
    formData.append('file', blob, uuid());

    return this._http
      .post(this.endpoint, formData, { params: httpParams })
      .pipe(
        map((res: any) => new Picture(deserializer.deserialize(res))),
        catchError(errorHandler)
      );
  }
}
