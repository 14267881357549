import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { LoggerService } from '@shared/services/logger.service';
import { TypeaheadService } from '@shared/services/typeahead.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html'
})
export class TypeaheadComponent implements OnInit, OnChanges {
  @ViewChild('typeaheadSelect', { static: true })
  public typeaheadSelect: ElementRef;

  @Input() public defaultValue: string;
  @Input() public filters: any[] = [];
  @Input() public isDisabled?: boolean = false;
  @Input() public resourceName: string;
  @Input() public placeholder?: string;
  @Input() public isRequired? = false;
  @Input() public service: any;
  @Input() public onGetValueFromAttributes;
  @Input() public onMatOptionSelect;
  @Input() public isMarginEnabled = true;
  @Input() public newPlaceholder = '';

  @Output()
  public defaultValueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public selectService: EventEmitter<string> = new EventEmitter<string>();

  public asyncSelected: string;
  public noResult = Boolean(false);
  public search: Observable<any>;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _typeahead: TypeaheadService,
    private readonly _logger: LoggerService
  ) {
    this.onEvery = this.onEvery.bind(this);
  }

  ngOnInit(): void {
    this._typeahead.isModalOpenCalled$.subscribe((res: boolean) => {
      if (!res) {
        this.asyncSelected = undefined;
        this.noResult = false;
      }
    });

    this.search = new Observable((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      switchMap((searchCriteria: string) => {
        if (!!searchCriteria) {
          const filters = this.filters.concat({
            key: 'search',
            value: searchCriteria
          });

          const url = `GET /${this.resourceName}`;
          return this.service.get(1, filters).pipe(
            map((data: any) => {
              this._logger.info(this.constructorName, url, data);
              return data.map(this.onEvery);
            }),
            catchError((err: any) => {
              this._logger.error(this.constructorName, url, err);

              return of([]);
            })
          );
        }

        return of([]);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultValue) {
      this.typeaheadSelect.nativeElement.value =
        changes.defaultValue.currentValue;
    }
  }

  public typeaheadNoResults(event: boolean) {
    this.noResult = event;
  }

  public handleSelect(event: TypeaheadMatch) {
    if (typeof this.onMatOptionSelect === 'function') {
      this.asyncSelected = event.item.value;
      this.onMatOptionSelect(event.item.record);
    }
  }

  public onEvery(option: any) {
    const value = this.onGetValueFromAttributes
      ? this.onGetValueFromAttributes(option)
      : `-`;

    return {
      typeaheadGroupField:
        option.typeaheadGroupField || this.resourceName.toUpperCase(),
      record: option,
      value
    };
  }
}
