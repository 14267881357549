import { getFormattedDate } from '@shared/helpers/status.helper';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IEvent, IEventStatistics } from '@shared/interfaces/event.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { Template } from '@shared/models/template.model';
import { User } from '@shared/models/user.model';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';
import { Address } from './address.model';
import { Asset } from './asset.model';

export class Event implements IEvent {
  public id: string;
  public user: IUser;
  public manager?: IUser;
  public location: ILocation;
  public parentLocation: ILocation;
  public parent: IEvent;
  public template: ITemplate;
  public asset?: Partial<IAsset>[];

  public lastChecklist: IChecklist;
  public parentLastChecklist: IChecklist;

  public label: string;
  public startDate: number;
  public dueDate: number;
  public recurrence: any;
  public step: number;
  public status: number;
  public statistics: IEventStatistics;
  public next: number;

  public isDeleted: boolean;
  public isPaused: boolean;
  public isWeekendExcluded: boolean;
  public isAllowSubmissionAfterDue: boolean;
  public allDay = Boolean(true);
  public address?: Address;

  public createdAt: Date;

  // ////////////////////
  // Table list
  // ////////////////////

  constructor(partial: Partial<IEvent>) {
    Object.assign(this, {
      ...partial,
      location:
        typeof partial?.location === 'object'
          ? new Location(partial.location)
          : partial?.location,
      user:
        typeof partial?.user === 'object'
          ? new User(partial.user)
          : partial?.user,
      manager:
        typeof partial?.manager === 'object'
          ? new User(partial.manager)
          : partial?.manager,
      parent:
        partial?.parent !== null && typeof partial?.parent === 'object'
          ? new Event(partial.parent)
          : partial?.parent,
      parentLocation:
        partial?.parentLocation !== null &&
          typeof partial?.parentLocation === 'object'
          ? new Location(partial.parentLocation)
          : partial?.parentLocation,
      template:
        typeof partial?.template === 'object'
          ? new Template(partial.template)
          : partial?.template,
    });
  }

  public getTimezone() {
    let timezone = tzlookup(this.address[0].lat, this.address[0].lng) as any;
    if (!timezone) {
      timezone = '';
    }
    return timezone;
  }

  public get submittedOnScheduled(): string {
    const totalSubmitted = this.statistics?.totalSubmitted;
    let totalScheduled = this.statistics?.totalScheduled;

    const isBeforeToday = moment()
      .tz(this.getTimezone())
      .isBefore(this.startDate, 'd');

    if (isBeforeToday || totalScheduled === 0 || totalSubmitted === 0) {
      return '-';
    }

    if (this.recurrence === 'w') {
      totalScheduled = totalScheduled / 7;
    } else if (this.recurrence === 'M') {
      totalScheduled = totalScheduled / 30.417;
    }

    totalScheduled = +totalScheduled.toFixed(0);

    let percent = (totalSubmitted * 100) / totalScheduled;
    percent = +percent.toFixed(2);
    return `${totalSubmitted} / ${totalScheduled} (${percent}%)`;
  }

  public get rowClassName() {
    return this.status === 5 ? 'opacity-50' : '';
  }

  public get dueTimeframe() {
    switch (this.recurrence) {
      case 'h':
      case 'd':
        return `${moment.unix(this.startDate).format('HH:mm')} - ${moment
          .unix(this.dueDate)
          .format('HH:mm')}`;

      case 'w':
        return `${moment.unix(this.startDate).format('dddd')} - ${moment
          .unix(this.dueDate)
          .tz(this.getTimezone())
          .format('dddd')}`;

      case 'M':
        return `${moment.unix(this.startDate).format('Do')} - ${moment
          .unix(this.dueDate)
          .format('Do')}`;

      case 'y':
        return `${moment.unix(this.startDate).format('MMM')} - ${moment
          .unix(this.dueDate)
          .format('MMM')}`;

      case 'o':
      default:
        return '-';
    }
  }

  public get nextLabel(): string {
    return getFormattedDate(this.next, this.recurrence, this.step);
  }

  public get pillsRecurrence(): any {
    let label;
    let className;

    switch (this.recurrence) {
      case 'h':
        label = 'Hourly';
        className = 'ohq-badge ohq-badge-recurrence-h';
        break;

      case 'o':
        label = 'One time';
        className = 'ohq-badge ohq-badge-recurrence-o';
        break;

      case 'd':
        label = 'Daily';
        className = 'ohq-badge ohq-badge-recurrence-d';
        break;

      case 'w':
        label = 'Weekly';
        className = 'ohq-badge ohq-badge-recurrence-w';

        if (this.step > 1) {
          label = `${this.step} week(s)`;
        }

        break;

      case 'M':
        label = 'Monthly';
        className = 'ohq-badge ohq-badge-recurrence-m';

        if (this.step > 1) {
          label = `${this.step} month(s)`;
        }

        break;

      case 'y':
        label = 'Yearly';
        className = 'ohq-badge ohq-badge-recurrence-y';
        break;

      default:
        label = '-';
        className = 'ohq-badge ohq-badge-light';
        break;
    }

    return {
      label,
      class: className
    };
  }

  public get pillsStatus() {
    if (this.recurrence === 'o' && this.isPaused) {
      return {
        label: 'STATUS.COMPLETED',
        class: 'ohq-badge-success'
      };
    } else if (this.isPaused) {
      return {
        label: 'STATUS.PAUSED',
        class: 'ohq-badge-light text-dark'
      };
    }

    switch (this.status) {
      case 1:
        return {
          label: 'STATUS.OVERDUE',
          class: 'ohq-badge-danger'
        };

      case 2:
      default:
        return {
          label: 'STATUS.SCHEDULED',
          class: 'ohq-badge-light text-dark'
        };
    }
  }

  public get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }
}
