import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { TableColType } from '@shared/enums/table-col-type.enum';
import { ICompany } from '@shared/interfaces/company.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IItem } from '@shared/interfaces/item.interface';
import { ITableCol } from '@shared/interfaces/table-col.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { AssetsService } from '@shared/services/assets.service';
import { CompaniesService } from '@shared/services/companies.service';
import { DocumentsService } from '@shared/services/documents.service';
import { LoggerService } from '@shared/services/logger.service';
import { TemplatesService } from '@shared/services/templates.service';
import { ToastService } from '@shared/services/toast.service';
import * as FileSaver from 'file-saver';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-import',
  templateUrl: './modals.import.component.html'
})
export class ModalsImportComponent
  extends ModalDirective<ITemplate>
  implements OnInit
{
  @Input() public openModal: Subject<any>;
  public title = String('MODALS.IMPORT.TITLE');
  public description = String('MODALS.IMPORT.DESCRIPTION');

  public entry: FormGroup;
  public errors: IError[] = [];
  public file: any;
  public upload: IDocument;
  public isChecked = Boolean(false);
  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');

  public templateType: string;

  public cols: Partial<ITableCol>[] = [
    {
      headerText: 'TABLES.HEADERS.DESCRIPTION',
      type: TableColType.Text,
      selector: 'description'
    }
  ];

  public params: any = {};

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _documents: DocumentsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService,

    public readonly _companies: CompaniesService,
    public readonly _assets: AssetsService,
    public readonly _templates: TemplatesService
  ) {
    super();

    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: any) => {
      this.isChecked = false;

      const template = (this.entry.get('template') as FormGroup).controls['id'];
      const assets = (this.entry.get('template') as FormGroup).controls['id'];
      const company = (this.entry.get('company') as FormGroup).controls['id'];
      this.description = res.admin ? String('MODALS.IMPORT.ASSET_DESCRIPTION') : String('MODALS.IMPORT.DESCRIPTION');
      const type = res?.type;

      if (type === 'template') {
        this.entry.get(type).patchValue({ id: res.id });
        this.templateType = 'items';

        template.setValidators([Validators.required]);
        company.setValidators([]);
        assets.setValidators([]);
      } else if(type === 'assets') {
        this.entry.get(type).patchValue({ id: res.id });
        this.templateType = 'assets';
        assets.setValidators([Validators.required]);
        company.setValidators([]);
        template.setValidators([]);
      }else {
        this.templateType = 'contacts';

        template.setValidators([]);
        assets.setValidators([]);
        company.setValidators([Validators.required]);
      }

      template.updateValueAndValidity();
      assets.updateValueAndValidity();
      company.updateValueAndValidity();
    });
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    this.errors = [];

    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;

          const url = 'POST /documents/check';
          this._documents.post(file, {}, true).subscribe(
            (res: any) => {
              this._logger.info(this.constructorName, url, res);

              this.upload = res;
              this.isChecked = true;
              this.params = {
                ...this.upload,
                type: this.templateType,
                subtype: this.templateType === 'items' || this.templateType === 'assets' ? 'form' : 'company'
              };

            },
            (err: any) => {
              this._logger.error(this.constructorName, url, err);

              const errors = err.errors;
              if (errors) {
                this.errors = errors;

                if (errors[0].detail) {
                  this._toasts.error(errors[0].detail);
                }
              }

              this.isChecked = false;
            }
          );
        });
      } else {
        this._toasts.error('Not a CSV file');
      }
    }
  }

  public downloadTemplate() {
    const filename = `templates_${this.templateType}.csv`;
    const url = `GET /documents/template?type=template`;
    this._documents.get('template', this.templateType).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        FileSaver.saveAs(res, filename);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public csvJSON(csv) {
    const lines = csv.split('\n');
    const result = [];
    const headers=lines[0].split(',');
    for(let i=1;i<lines.length;i++){
        const obj = {};
        const currentLine =lines[i].split(',');
        for(let j=0;j<headers.length;j++){
            obj[headers[j]] = currentLine[j];
        }
        result.push(JSON.parse(JSON.stringify(obj)));
    }
    return result;
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      let id = value.template.id;
      let url = `POST /templates/${id}/items`;
      let service = '_templates';
      let fn = 'postItems';

      if (this.templateType === 'contacts') {
        id = value.company.id;
        url = `POST /companies/${id}/contacts`;
        service = '_companies';
        fn = 'postContacts';
      }

      if (this.templateType === 'assets') {
        id = value.company.id;
        url = `POST /assets/import`;
        service = '_assets';
        fn = 'importCsv';

        const readerObj = new FileReader();
        readerObj.onload = () => {
          const fileText = readerObj.result;
          const rows = this.csvJSON(fileText);
          this.file = {
            data: rows
          };
          this.callSubmit(service, fn, null, url);
        };
        readerObj.readAsText(this.file);
      }else {
        this.callSubmit(service, fn, id, url);
      };
    }
  }

  public callSubmit(service, fn, id, url) {
    id ? this[service][fn](id, this.file).subscribe(
      (res: IItem[]) => {
        this._logger.info(this.constructorName, url, res);
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;

        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.isChecked = false;
      }
    )
    :
    this[service][fn](this.file).subscribe(
      (res: IItem[]) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;

        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.isChecked = false;
      }
    );
  }

  public onGetValueFromAttributes(e: ICompany) {
    return e.name;
  }

  public onMatOptionSelect(e: any): void {
    const type = 'company';

    this.entry.get(type).patchValue({
      id: e.id
    });
  }

  protected createForm() {
    this.entry = this._fb.group({
      template: this._fb.group({
        id: ['']
      }),
      assets: this._fb.group({
        id: ['']
      }),
      company: this._fb.group({
        id: ['']
      })
    });
  }
}
