import { ILibraryCategory } from '@shared/interfaces/library-category.interface';

export class LibraryCategory implements LibraryCategory {
  id: string;
  name: string;

  constructor(partial: Partial<ILibraryCategory>) {
    Object.assign(this, partial);
  }
}
