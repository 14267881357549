import { IPicture } from '@shared/interfaces/picture.interface';

export class Picture implements IPicture {
  public id: string;
  public ownerId: string;
  public ownerType: string;
  public type: string;
  public url: string;
  public isDeleted: boolean;

  constructor(partial: Partial<IPicture>) {
    Object.assign(this, partial);
  }
}
