import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICompany } from '@shared/interfaces/company.interface';
import { IContact } from '@shared/interfaces/contact.interface';
import { IError } from '@shared/interfaces/error.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { CompaniesService } from '@shared/services/companies.service';
import { ContactsService } from '@shared/services/contacts.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import { NgxSelectOption } from 'ngx-select-ex';
import { Subject } from 'rxjs';
import { userContactTypes, createLoginTypes, roleList } from '@shared/helpers/types.helper';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IDocument } from '@shared/interfaces/document.interface';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { AssetCategorysService } from '@shared/services/assets-categories.service';
@Component({
  selector: 'app-modals-contacts',
  templateUrl: './modals.contacts.component.html'
})
export class ModalsContactsComponent
  extends ModalDirective<Partial<IContact>>
  implements OnInit {
  @Input() public openModal: Subject<Partial<IContact>>;

  public defaultCompanyValue: string;
  public defaultSubLocationValue: string;

  public title = String('MODALS.CONTACTS.ADD.TITLE');
  public description = String('MODALS.CONTACTS.ADD.DESCRIPTION');
  public entry: FormGroup;
  public disableButtons = Boolean(false);
  public isUploading = Boolean(false);
  public isRoot = Boolean(false);
  public userTypes: { label: string; value: string }[] = userContactTypes;
  public roleList: { label: string; value: string }[] = roleList;
  public createLoginTypes: { label: string; value: boolean }[] = createLoginTypes;

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;

  public errors: IError[] = [];
  public locations: Partial<ILocation>[] = [];
  public subLocations: Partial<ILocation>[] = [];
  public file: File;
  public isEditing = Boolean(false);
  public id: string;
  public files: NgxFileDropEntry[] = [];
  public items: any[] = [];
  public isLoading = Boolean(true);
  public showSubLocation: Boolean = true;
  public categories: Partial<IAssetCategory>[] = [];

  public subLocationsFilters: any[] = [
    {
      key: 'pagination',
      value: false
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _assetsCategory: AssetCategorysService,
    public readonly _locations: LocationsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _contacts: ContactsService,
    private readonly _toasts: ToastService,
    public readonly _companies: CompaniesService
  ) {
    super();

    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
    this.onGetValueFromAttributesLocation = this.onGetValueFromAttributesLocation.bind(this);
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
  }

  ngOnInit(): void {
    this.createForm();
    this.getCategories();

    this.openModal.subscribe((c: IContact) => {
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
      this.isRoot = Boolean(payload.employeeRole === undefined);

      if (c?.id) {
        this.id = c.id;
        this.isEditing = true;
        this.title = 'MODALS.CONTACTS.EDIT.TITLE';
        this.description = 'MODALS.CONTACTS.EDIT.DESCRIPTION';
        this.deleteKey = 'BUTTONS.DELETE';

        this.defaultCompanyValue = c.company.name;
        this.defaultSubLocationValue = c?.subLocation?.id ? c?.subLocation?.label : ''

        this.entry.patchValue({
          ...c,
          locations: c.locations.map((l: ILocation) => l.id),
          subLocation: c?.subLocation?.id ? { id: c?.subLocation?.id } : null,
          categories: c.categories?.map((item: any) => item?.id),
        });
        this.isLoading = true;
        this.getSubLocation();
        const url = `GET /contacts/${this.id}/documents`;
        this._contacts.getDocuments(this.id).subscribe(
          (res: IDocument[]) => {
            this._logger.info(this.constructorName, url, res);
            this.items = res;
            this.isLoading = false;
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
            this.isLoading = false;
          }
        );
      } else {
        this.items = [];
        this.title = 'MODALS.CONTACTS.ADD.TITLE';
        this.description = 'MODALS.CONTACTS.ADD.DESCRIPTION';

        this.defaultCompanyValue = undefined;
        this.defaultSubLocationValue = undefined;

        this.deleteKey = null;

        this.entry.patchValue({
          userType: 'CONTRACTOR',
          company: {
            id: payload.company
          },
          countryCode: '1',
          role: '',
          locations: [],
          subLocation: ''
        });
      }

      this.getParentLocations();
    });
  }

  public getCategories() {
    const url = `GET /assets-categories`;
    this._assetsCategory.get(1, []).subscribe(
      (res: IAssetCategory[]) => {
        this.categories = res;
        this.createForm();
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }


  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.files = files;
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;
          if (this.entry.get('fileNames').value) {
            this.entry.patchValue({
              fileNames: [...this.entry.get('fileNames').value, file.name]
            });
          } else {
            this.entry.patchValue({
              fileNames: [file.name]
            });
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /contacts`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /contacts/${value.id}`;
      }

      value.locations = value.locations.map((id: string) => ({ id }));
      if (value.categories) {
        value.categories = value.categories.map((id: string) => ({ id }));
      }
      // value.subLocation = {id: value.subLocation};

      this._contacts[method](value).subscribe(
        (res: IContact) => {
          this._logger.info(this.constructorName, url, res);

          this.entry.enable();
          this.entry.reset();
          this.disableButtons = false;
          this.id = res.id;
          this.isUploading = true;
          for (const f of this.files) {
            if (f.fileEntry.isFile) {
              const url2 = `POST /contacts/${this.id}/documents`;
              const filters: any = [
                {
                  key: 'ownerId',
                  value: this.id
                },
                {
                  key: 'ownerType',//company
                  value: 'Contact'
                },
                {
                  key: 'type',
                  value: 'document'
                }
              ];
              const fileEntry = f.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                this._contacts.postDocument(file, filters).subscribe(
                  (res2: IDocument) => {
                    this._logger.info(this.constructorName, url2, res2);
                    if (this.entry.get('fileNames').value) {
                      this.entry.patchValue({
                        fileNames: [...this.entry.get('fileNames').value, file.name]
                      });
                    } else {
                      this.entry.patchValue({
                        fileNames: [file.name]
                      });
                    }
                    if (this.entry.get('files').value) {
                      this.entry.patchValue({
                        files: [...this.entry.get('files').value, res2.id]
                      });
                    } else {
                      this.entry.patchValue({
                        files: [res2.id]
                      });
                    }
                    this.isUploading = false;
                    this.resetModal();
                  },
                  (err: Error) => {
                    this.resetModal();
                    this.isUploading = false;
                    this._logger.error(this.constructorName, url, err);
                    this._toasts.error('Upload failed');
                  }
                );
              });
            }
          }
          if (this.files.length === 0) {
            this.isUploading = false;
            this.resetModal();
          }
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;
          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.disableButtons = false;
          this.entry.enable();
        }
      );
    }
  }

  public selectChangesLocations(e: NgxSelectOption[]) {
    const locations = e.map((ee: NgxSelectOption) => ee.value);
    this.entry.patchValue({ locations: locations });
    if (this.entry.get('locations')?.value.length === 0) {
      this.showSubLocation = false;
      setTimeout(() => {
        this.showSubLocation = true
        this.entry.patchValue({
          subLocation: { id: null }
        });
        this.defaultSubLocationValue = ''

      }, 1000);
    }
    this.getSubLocation();
  }

  public selectChangesSubLocation(e: NgxSelectOption[]) {
    const subLocation = e.map((ee: NgxSelectOption) => ee.value);
    this.entry.patchValue(subLocation);
  }

  public onGetValueFromAttributes(e: ICompany) {
    return e.name;
  }

  public onGetValueFromAttributesLocation(e: ILocation) {
    return e.label;
  }

  public onMatOptionSelect(e: any): void {
    const type = 'company';

    this.entry.get(type).patchValue({
      id: e.id
    });
  }

  public onMatOptionSelectSubLocation(e: ILocation): void {
    this.entry.get('subLocation').patchValue({
      id: e.id
    });
  }

  public makeSubLocationNotRequired() {
    if (this.entry.get('userType').value === 'TENANT') {
      const subLocationGroup = this.entry.get('subLocation');
      if (subLocationGroup) {
        const idControl = subLocationGroup.get('id');
        if (idControl) {
          idControl.setValidators([]);
          idControl.updateValueAndValidity();
        }
      }
    }

  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      files: [''],
      fileNames: [''],
      companyName: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.email, Validators.email]],
      phone: ['', Validators.required],
      notes: [''],
      createLogin: [true],
      role: ['', []],
      userType: ['CONTRACTOR', Validators.required],
      countryCode: ['1', Validators.required],
      company: this._fb.group({
        id: ['', []]
      }),
      locations: ['', Validators.required],
      subLocation: this._fb.group({
        id: ['', []]
      }),
      categories: ['', []],
    });
  }

  private getParentLocations() {
    const locationsFilters: any[] = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = `GET /locations`;
    this._locations.get(1, locationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.locations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private getSubLocation() {

    if (this.entry.get('locations').value?.length > 0) {

      this.subLocationsFilters = [
        {
          key: 'parent',
          value: this.entry.get('locations').value[0]
        },
        {
          key: 'pagination',
          value: 'false'
        },
      ];
    }

    const url = `GET /locations`;
    this._locations.getSubLocations(1, this.subLocationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.subLocations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /contacts/${id}`;

    this._contacts.delete(id).subscribe(
      (res: IContact) => {
        this._logger.info(this.constructorName, url, res);

        this.entry.enable();
        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }
}
