import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { dataURLtoBlob } from '@shared/helpers/b64.helper';
import { priorities } from '@shared/helpers/types.helper';
import { IError } from '@shared/interfaces/error.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { LoggerService } from '@shared/services/logger.service';
import { PicturesService } from '@shared/services/pictures.service';
import { ToastService } from '@shared/services/toast.service';
import { SignaturePad } from 'ngx-signaturepad';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-signature',
  templateUrl: './modals.signature.component.html'
})
export class ModalsSignatureComponent
  extends ModalDirective<IUser>
  implements OnInit
{
  @Input() public openModal: Subject<IUser>;
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public title = String('MODALS.SIGNATURE.TITLE');
  public description = String('MODALS.SIGNATURE.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public disableButtons = Boolean(false);
  public index: number;

  public users: IUser[] = [];
  public priorities: { label: string; value: number }[] = priorities;

  public errors: IError[] = [];
  public entry: FormGroup;

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _pictures: PicturesService,
    private readonly _toats: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: any) => {
      if (res?.index) {
        this.clear();
        this.index = res.index;
      }
    });
  }

  public clear() {
    this.entry.patchValue({ signature: null });
    this.signaturePad.clear();
  }

  public drawComplete() {
    this.entry.patchValue({
      signature: this.signaturePad.toDataURL()
    });
  }

  public onSubmit({ valid }: { valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      const filters: any = [
        {
          key: 'ownerType',
          value: 'User'
        },
        {
          key: 'type',
          value: 'signature'
        }
      ];

      if (this.index) {
        filters[0].value = 'Item';
      }

      const url = `POST /pictures`;
      const blob: Blob = dataURLtoBlob(this.signaturePad.toDataURL());
      this._pictures.post(filters, blob).subscribe(
        (res: IPicture) => {
          this._logger.info(this.constructorName, url, res);

          this._toats.success('Signature updated');
          this.disableButtons = false;
          this.resetModal({
            index: this.index,
            signatureId: res.id
          });
        },
        (err: Error) => {
          this._logger.error(this.constructorName, url, err);
          this._toats.error('Signature update failed');
          this.disableButtons = false;
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      signature: ['', [Validators.required]]
    });
  }
}
