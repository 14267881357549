import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Activity } from '@shared/models/activity.model';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/comments`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IActivity[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: IActivity) => new Activity(a))
      ),
      catchError(errorHandler)
    );
  }

  getComments(taskId: string): Observable<any> {
    return this._http.get(`${this.endpoint}/${taskId}`).pipe(
      map((res: any) => new Object(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }
 
  getMessages(page): Observable<any> {
    return this._http.get(`${this.endpoint}/threads/user?page=${page}&limit=30`).pipe(
      map((res: any) => new Object(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  createComment(
    text: string,
    parentId: string | null = null,
    taskId: string
  ): Observable<any> {
    return this._http.post<any>(
      this.endpoint,
      {
        body: text,
        parentId,
        userId: '',
        username: '',
        taskId
      }
    );
  }

  updateComment(id: string, text: string): Observable<any> {
    return this._http.patch<any>(
      this.endpoint+`/${id}`,
      {
        body: text,
      }
    );
  }

  deleteComment(id: string): Observable<any> {
    return this._http.delete(`${this.endpoint}/${id}`);
  }
}
