import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { Checklist } from '@shared/models/checklist.model';
import { ChecklistsService } from '@shared/services/checklists.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import jwtDecode from 'jwt-decode';
import { forkJoin, Subject } from 'rxjs';

@Component({
  selector: 'app-modals-reviews',
  templateUrl: './modals.reviews.component.html'
})
export class ModalsReviewsComponent
  extends ModalDirective<string[]>
  implements OnInit
{
  @Input() public openModal: Subject<any>;

  public title = String('MODALS.REVIEWS.TITLE');
  public description = String('MODALS.REVIEWS.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');

  public type = String('document');
  public disableButtons = false;

  public entry: FormGroup;

  public checklists: IChecklist[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _checklists: ChecklistsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((res: IChecklist[]) => {
      this.checklists = res;
    });
  }

  public onSubmit() {
    this.disableButtons = true;

    const url = 'PATCH /checklists/id';

    const token = this._commonEnvironments.getToken();
    const payload: IPayload = jwtDecode(token);

    const checklistsToUpdate = [];

    this.checklists.map((c: IChecklist) => {
      const checklist = new Checklist({
        ...c,
        reviewedAt: new Date(),
        reviewedBy: {
          id: payload.id
        }
      });

      checklistsToUpdate.push(this._checklists.patch(checklist));
    });

    forkJoin(checklistsToUpdate).subscribe(
      (res) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
        this.disableButtons = false;
      },
      (err: any) => {
        this._logger.error(this.constructorName, `${url} failed`, err);
        this._toasts.error('Checklist update failed');
      }
    );

    // this.patchChecklist(this.entry.value);

    // const checklist = new Checklist(c);
    // this._checklists.patch(checklist).subscribe(
    //   (res: IChecklist) => {

    //     this.checklist = res;
    //     if (!!res.reviewedAt) {
    //       this.entry.disable();
    //     } else {
    //       this._toasts.success('Checklist updated');
    //     }
    //     this._socket.emit(`activities`, res);
    //   },
    //   (err: any) => {
    //     this._logger.error(this.constructorName, `${url} failed`, err);
    //     this._toasts.error('Checklist update failed');
    //   }
    // );
  }

  protected createForm() {
    this.entry = this._fb.group({});
  }
}
