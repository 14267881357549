import { IAssetCategory } from '@shared/interfaces/asset-category.interface';

export class AssetCategory implements AssetCategory {
  id: string;
  name: string;

  constructor(partial: Partial<IAssetCategory>) {
    Object.assign(this, partial);
  }
}
