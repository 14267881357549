import { Injectable } from '@angular/core';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { AssetsService } from '@shared/services/assets.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationAssetsService extends PaginationService<IAsset> {
  private static readonly DEFAULT_IS_DELETED_FILTER: IFilter = {
    label: 'COMMON.ACTIVE',
    key: 'isDeleted',
    value: 'false'
  };

  private static readonly DEFAULT_PARENT_LOCATION_FILTER: IFilter = {
    key: 'parentLocation',
    value: null
  };

  private static readonly DEFAULT_SUB_LOCATION_FILTER: IFilter = {
    key: 'subLocation',
    value: null
  };

  private _filtersScope: any;

  constructor(_paginable: AssetsService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationAssetsService.DEFAULT_IS_DELETED_FILTER,
      PaginationAssetsService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationAssetsService.DEFAULT_SUB_LOCATION_FILTER
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationAssetsService.DEFAULT_IS_DELETED_FILTER,
      PaginationAssetsService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationAssetsService.DEFAULT_SUB_LOCATION_FILTER
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
