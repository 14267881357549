import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { priorities } from '@shared/helpers/types.helper';
import { IAction } from '@shared/interfaces/action.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Employee } from '@shared/models/employee.model';
import { Location } from '@shared/models/location.model';
import { ActionsService } from '@shared/services/actions.service';
import { EmployeesService } from '@shared/services/employees.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-actions',
  templateUrl: './modals.actions.component.html'
})
export class ModalsActionsComponent
  extends ModalDirective<IAction>
  implements OnInit
{
  @Input() public openModal: Subject<IAction>;

  public defaultLocationValue: string;
  public defaultEmployeeValue: string;

  public title = String('MODALS.ACTIONS.ADD.TITLE');
  public description = String('MODALS.ACTIONS.ADD.DESCRIPTION');

  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public disableButtons = Boolean(false);
  public locationsFilters: any[] = [
    {
      key: 'parent',
      value: true
    }
  ];

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: '3'
    }
  ];

  public entry: FormGroup;
  public locations: ILocation[] = [];
  public users: IUser[] = [];
  public priorities: { label: string; value: number }[] = priorities;

  public errors: IError[] = [];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    public readonly _locations: LocationsService,
    public readonly _employees: EmployeesService,

    private readonly _actions: ActionsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((a: IAction) => {
      if (a) {
        this.defaultLocationValue = a.location.label;
        this.defaultEmployeeValue = a.user.fullName;

        this.title = 'MODALS.ACTIONS.EDIT.TITLE';
        this.description = 'MODALS.ACTIONS.EDIT.DESCRIPTION';
        this.deleteKey = 'BUTTONS.DELETE';

        this.entry.patchValue(a);
      } else {
        this.title = 'MODALS.ACTIONS.ADD.TITLE';
        this.description = 'MODALS.ACTIONS.ADD.DESCRIPTION';

        this.defaultLocationValue = undefined;
        this.defaultEmployeeValue = undefined;
        this.deleteKey = null;

        this.entry.patchValue({
          pictures: [],
          isSendEmailSelected: false
        });
      }
    });
  }

  public getImagePath(id: string) {
    return `${id}`;
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /actions`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /actions/${value.id}`;
      }

      this._actions[method](value).subscribe(
        (res: IAction) => {
          this._logger.info(this.constructorName, url, res);

          this.reset();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;
          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
          this.disableButtons = false;
        }
      );
    }
  }

  public onMatOptionSelect(e: any): void {
    let type = 'location';
    let id = e.id;

    if (e instanceof Employee) {
      type = 'user';
      id = e.user.id;
    }

    this.entry.get(type).patchValue({
      id
    });
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Location) {
      return `${e.label} (${e.parent.label})`;
    } else {
      return `${e.fullName} ${e.email ? '(' + e.email + ')' : null}`;
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', [Validators.required]],
      description: ['', [Validators.required]],
      priority: ['', [Validators.required]],
      pictures: [[]],
      user: this._fb.group({
        id: ['', [Validators.required]],
        isSendEmailSelected: [false]
      }),
      location: this._fb.group({
        id: ['', [Validators.required]]
      })
    });
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /actions/${id}`;

    this._actions.delete(id).subscribe(
      (res: IAction) => {
        this._logger.info(this.constructorName, url, res);

        this.reset();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }

  private reset() {
    this.disableButtons = false;
    this.entry.enable();
    this.resetModal();
  }
}
