export const roleList = [
  {
    label: 'Access Control',
    value: 'Access Control'
  },
  {
    label: 'Account Executive',
    value: 'Account Executive'
  },
  {
    label: 'Account Manager',
    value: 'Account Manager'
  },
  {
    label: 'Accounts Payable',
    value: 'Accounts Payable'
  },
  {
    label: 'Accounts Receivable Specialist',
    value: 'Accounts Receivable Specialist'
  },
  {
    label: 'Business Administration Manager',
    value: 'Business Administration Manager'
  },
  {
    label: 'Claims Adjuster',
    value: 'Claims Adjuster'
  },
  {
    label: 'Client Relations Manager',
    value: 'Client Relations Manager'
  },
  {
    label: 'Commercial Realty Consultance',
    value: 'Commercial Realty Consultance'
  },
  {
    label: 'Electrician',
    value: 'Electrician'
  },
  {
    label: 'Elevators',
    value: 'Elevators'
  },
  {
    label: 'Emergency Generator',
    value: 'Emergency Generator'
  },
  {
    label: 'Executive General Adjuster',
    value: 'Executive General Adjuster'
  },
  {
    label: 'Fire Monitoring',
    value: 'Fire Monitoring'
  },
  {
    label: 'Fire Safety',
    value: 'Fire Safety'
  },
  {
    label: 'Fire System Technician',
    value: 'Fire System Technician'
  },
  {
    label: 'Garbage Disposal Company',
    value: 'Garbage Disposal Company'
  },
  {
    label: 'Glazing',
    value: 'Glazing'
  },
  {
    label: 'Graffiti Removal',
    value: 'Graffiti Removal'
  },
  {
    label: 'HVAC',
    value: 'HVAC'
  },
  {
    label: 'Landscaper',
    value: 'Landscaper'
  },
  {
    label: 'Lead Tech',
    value: 'Lead Tech'
  },
  {
    label: 'Lockmasters Dispatch',
    value: 'Lockmasters Dispatch'
  },
  {
    label: 'Locksmith',
    value: 'Locksmith'
  },
  {
    label: 'Operations Manager',
    value: 'Operations Manager'
  },
  {
    label: 'Owner',
    value: 'Owner'
  },
  {
    label: 'Painting',
    value: 'Painting'
  },
  {
    label: 'Pest Control',
    value: 'Pest Control'
  },
  {
    label: 'Pest Control Provider',
    value: 'Pest Control Provider'
  },
  {
    label: 'Plumbing Field Leader',
    value: 'Plumbing Field Leader'
  },
  {
    label: 'Principle',
    value: 'Principle'
  },
  {
    label: 'Project Coordinator',
    value: 'Project Coordinator'
  },
  {
    label: 'Restoration',
    value: 'Restoration'
  },
  {
    label: 'Roofing',
    value: 'Roofing'
  },
  {
    label: 'Security Contractor',
    value: 'Security Contractor'
  },
  {
    label: 'Senior Management',
    value: 'Senior Management'
  },
  {
    label: 'Service Coordinator',
    value: 'Service Coordinator'
  },
  {
    label: 'Snow Removal',
    value: 'Snow Removal'
  },
  {
    label: 'Technician',
    value: 'Technician'
  },
  {
    label: 'Vice President',
    value: 'Vice President'
  },
  {
    label: 'Waste Disposal & Recycling',
    value: 'Waste Disposal & Recycling'
  },
  {
    label: 'Window Cleaning',
    value: 'Window Cleaning'
  }
];


export const hasStartDateOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Add Start Date'
  }
];

export const hasDueDateOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Add Due Date'
  }
];

export const hasStartTimeOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Add Start Time'
  }
];

export const hasDueTimeOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Add Due Time'
  }
];

export const excludeWeekendOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Exclude weekends'
  }
];

export const customizeDatesOptions = [
  {
    value: false,
    label: 'NO'
  },
  {
    value: true,
    label: 'Customize Dates'
  }
];


export const roleTypes = [
  // {
  //   label: 'Root',
  //   value: 0
  // },
  {
    label: 'User',
    value: 1
  }
];

export const employeeRolesTypes = [
  {
    label: 'Admin',
    value: 0
  },
  {
    label: 'Manager',
    value: 1
  },
  // NOT USED
  // {
  //   label: 'Analytics',
  //   value: 2
  // },
  {
    label: 'Employee',
    value: 3
  }
];

export const itemTypes = [
  {
    label: 'Yes / No',
    value: 0
  },
  {
    label: 'Text',
    value: 1
  },
  {
    label: 'Number',
    value: 2
  },
  {
    label: 'Date',
    value: 3
  },
  {
    label: 'Signature',
    value: 4
  },
  {
    label: 'Telephone',
    value: 5
  },
  {
    label: 'Dropdown',
    value: 6
  },
  {
    label: 'Meter Reading',
    value: 7
  }
];

export const fileMimeType = {
  'audio/x-mpeg': 'mpega',
  'application/postscript': 'ps',
  'audio/x-aiff': 'aiff',
  'application/x-aim': 'aim',
  'image/x-jg': 'art',
  'video/x-ms-asf': 'asx',
  'audio/basic': 'ulw',
  'video/x-msvideo': 'avi',
  'video/x-rad-screenplay': 'avx',
  'application/x-bcpio': 'bcpio',
  'application/octet-stream': 'exe',
  'image/bmp': 'dib',
  'text/html': 'html',
  'application/x-cdf': 'cdf',
  'application/pkix-cert': 'cer',
  'application/java': 'class',
  'application/x-cpio': 'cpio',
  'application/x-csh': 'csh',
  'text/css': 'css',
  'application/msword': 'doc',
  'application/xml-dtd': 'dtd',
  'video/x-dv': 'dv',
  'application/x-dvi': 'dvi',
  'application/vnd.ms-fontobject': 'eot',
  'text/x-setext': 'etx',
  'image/gif': 'gif',
  'application/x-gtar': 'gtar',
  'application/x-gzip': 'gz',
  'application/x-hdf': 'hdf',
  'application/mac-binhex40': 'hqx',
  'text/x-component': 'htc',
  'image/ief': 'ief',
  'text/vnd.sun.j2me.app-descriptor': 'jad',
  'application/java-archive': 'jar',
  'text/x-java-source': 'java',
  'application/x-java-jnlp-file': 'jnlp',
  'image/jpeg': 'jpg',
  'application/javascript': 'js',
  'text/plain': 'txt',
  'application/json': 'json',
  'audio/midi': 'midi',
  'application/x-latex': 'latex',
  'audio/x-mpegurl': 'm3u',
  'image/x-macpaint': 'pnt',
  'text/troff': 'tr',
  'application/mathml+xml': 'mathml',
  'application/x-mif': 'mif',
  'video/quicktime': 'qt',
  'video/x-sgi-movie': 'movie',
  'audio/mpeg': 'mpa',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpg',
  'video/mpeg2': 'mpv2',
  'application/x-wais-source': 'src',
  'application/x-netcdf': 'nc',
  'application/oda': 'oda',
  'application/vnd.oasis.opendocument.database': 'odb',
  'application/vnd.oasis.opendocument.chart': 'odc',
  'application/vnd.oasis.opendocument.formula': 'odf',
  'application/vnd.oasis.opendocument.graphics': 'odg',
  'application/vnd.oasis.opendocument.image': 'odi',
  'application/vnd.oasis.opendocument.text-master': 'odm',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.oasis.opendocument.graphics-template': 'otg',
  'application/vnd.oasis.opendocument.text-web': 'oth',
  'application/vnd.oasis.opendocument.presentation-template': 'otp',
  'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
  'application/vnd.oasis.opendocument.text-template': 'ott',
  'application/ogg': 'ogx',
  'video/ogg': 'ogv',
  'audio/ogg': 'spx',
  'application/x-font-opentype': 'otf',
  'audio/flac': 'flac',
  'application/annodex': 'anx',
  'audio/annodex': 'axa',
  'video/annodex': 'axv',
  'application/xspf+xml': 'xspf',
  'image/x-portable-bitmap': 'pbm',
  'image/pict': 'pict',
  'application/pdf': 'pdf',
  'image/x-portable-graymap': 'pgm',
  'audio/x-scpls': 'pls',
  'image/png': 'png',
  'image/x-portable-anymap': 'pnm',
  'image/x-portable-pixmap': 'ppm',
  'application/vnd.ms-powerpoint': 'pps',
  'image/vnd.adobe.photoshop': 'psd',
  'image/x-quicktime': 'qtif',
  'image/x-cmu-raster': 'ras',
  'application/rdf+xml': 'rdf',
  'image/x-rgb': 'rgb',
  'application/vnd.rn-realmedia': 'rm',
  'application/rtf': 'rtf',
  'text/richtext': 'rtx',
  'application/font-sfnt': 'sfnt',
  'application/x-sh': 'sh',
  'application/x-shar': 'shar',
  'application/x-stuffit': 'sit',
  'application/x-sv4cpio': 'sv4cpio',
  'application/x-sv4crc': 'sv4crc',
  'image/svg+xml': 'svgz',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'application/x-tcl': 'tcl',
  'application/x-tex': 'tex',
  'application/x-texinfo': 'texinfo',
  'image/tiff': 'tiff',
  'text/tab-separated-values': 'tsv',
  'application/x-font-ttf': 'ttf',
  'application/x-ustar': 'ustar',
  'application/voicexml+xml': 'vxml',
  'image/x-xbitmap': 'xbm',
  'application/xhtml+xml': 'xhtml',
  'application/vnd.ms-excel': 'xls',
  'application/xml': 'xsl',
  'image/x-xpixmap': 'xpm',
  'application/xslt+xml': 'xslt',
  'application/vnd.mozilla.xul+xml': 'xul',
  'image/x-xwindowdump': 'xwd',
  'application/vnd.visio': 'vsd',
  'audio/x-wav': 'wav',
  'image/vnd.wap.wbmp': 'wbmp',
  'text/vnd.wap.wml': 'wml',
  'application/vnd.wap.wmlc': 'wmlc',
  'text/vnd.wap.wmlsc': 'wmls',
  'application/vnd.wap.wmlscriptc': 'wmlscriptc',
  'video/x-ms-wmv': 'wmv',
  'application/font-woff': 'woff',
  'application/font-woff2': 'woff2',
  'model/vrml': 'wrl',
  'application/wspolicy+xml': 'wspolicy',
  'application/x-compress': 'z',
  'application/zip': 'zip'
};

export const itemValues = [
  {
    label: 'Yes',
    value: '1'
  },
  {
    label: 'No',
    value: '2'
  },
  {
    label: 'n/a',
    value: '0'
  }
];

export const eventTypes = [
  {
    label: 'All events',
    value: 'all'
  },
  {
    label: 'Not completed',
    value: 'notCompleted'
  },
  {
    label: 'Verify',
    value: 'verify'
  },
  {
    label: 'Completed',
    value: 'completed'
  },
  {
    label: 'Archived',
    value: 'archived'
  }
];

export const tasksStatus = [
  {
    label: 'Open',
    value: 'OPEN'
  },
  {
    label: 'On Hold',
    value: 'ON_HOLD'
  },
  {
    label: 'In Progress',
    value: 'IN_PROGRESS'
  },
  {
    label: 'Completed',
    value: 'COMPLETED'
  },
  {
    label: 'Closed',
    value: 'CLOSED'
  }
];

export const priorities = [
  {
    label: 'Low',
    value: 0
  },
  {
    label: 'Medium',
    value: 1
  },
  {
    label: 'High',
    value: 2
  }
];

export const recurringPriorities = [
  {
    label: 'Low',
    value: 'LOW',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'High',
    value: 'HIGH',
  }
];

export const recurrences = [
  {
    label: 'One-time',
    value: 'o'
  },
  {
    label: 'Hourly',
    value: 'h'
  },
  {
    label: 'Daily',
    value: 'd'
  },
  {
    label: 'Weekly',
    value: 'w'
  },
  {
    label: 'Monthly',
    value: 'M'
  },
  {
    label: 'Annually',
    value: 'y'
  }
];

export const weeklyDays = [
  {
    label: 'MON',
    value: 'MON'
  },
  {
    label: 'TUE',
    value: 'TUE'
  },
  {
    label: 'WED',
    value: 'WED'
  },
  {
    label: 'THU',
    value: 'THU'
  },
  {
    label: 'FRI',
    value: 'FRI'
  },
  {
    label: 'SAT',
    value: 'SAT'
  },
  {
    label: 'SUN',
    value: 'SUN'
  }
];

export const yearlyMonths = [
  {
    label: 'JAN',
    value: 'JAN'
  },
  {
    label: 'FEB',
    value: 'FEB'
  },
  {
    label: 'MAR',
    value: 'MAR'
  },
  {
    label: 'APR',
    value: 'APR'
  },
  {
    label: 'MAY',
    value: 'MAY'
  },
  {
    label: 'JUN',
    value: 'JUN'
  },
  {
    label: 'JUL',
    value: 'JUL'
  },
  {
    label: 'AUG',
    value: 'AUG'
  },
  {
    label: 'SEP',
    value: 'SEP'
  },
  {
    label: 'OCT',
    value: 'OCT'
  },
  {
    label: 'NOV',
    value: 'NOV'
  },
  {
    label: 'DEC',
    value: 'DEC'
  }
];

export const monthlyDays = [
  {
    label: '01',
    value: '01'
  },
  {
    label: '02',
    value: '02'
  },
  {
    label: '03',
    value: '03',
  },
  {
    label: '04',
    value: '04',
  },
  {
    label: '05',
    value: '05',
  },
  {
    label: '06',
    value: '06',
  },
  {
    label: '07',
    value: '07',
  },
  {
    label: '08',
    value: '08',
  },
  {
    label: '09',
    value: '09',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
  {
    label: '16',
    value: '16',
  },
  {
    label: '17',
    value: '17',
  },
  {
    label: '18',
    value: '18',
  },
  {
    label: '19',
    value: '19',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '21',
    value: '21',
  },
  {
    label: '22',
    value: '22',
  },
  {
    label: '23',
    value: '23',
  },
  {
    label: '24',
    value: '24',
  },
  {
    label: '25',
    value: '25',
  },
  {
    label: '26',
    value: '26',
  },
  {
    label: '27',
    value: '27',
  },
  {
    label: '28',
    value: '28',
  },
  {
    label: '29',
    value: '29',
  },
  {
    label: '30',
    value: '30',
  },
  {
    label: '31',
    value: '31',
  }
];

export const recurrenceForSteps = [
  {
    label: 'Hours',
    value: 'h'
  },
  {
    label: 'Weeks',
    value: 'w'
  },
  {
    label: 'Months',
    value: 'M'
  }
];


export const taskRecurrences = [
  {
    label: 'One time',
    value: 'NONE'
  },
  {
    label: 'Daily',
    value: 'DAILY'
  },
  {
    label: 'Weekly',
    value: 'WEEKLY'
  },
  {
    label: 'Monthly',
    value: 'MONTHLY'
  },
  {
    label: 'Yearly',
    value: 'YEARLY'
  }
];

export const recurringTaskStatus = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Paused',
    value: 'PAUSED'
  }
];

export const userTypes = [
  {
    label: 'Employee',
    value: 'employee'
  },
  {
    label: 'Vendor',
    value: 'contractor'
  }
];

export const userContactFilterTypes = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Vendor',
    value: 'CONTRACTOR'
  },
  {
    label: 'Occupant',
    value: 'TENANT'
  }
];

export const userContactTypes = [
  {
    label: 'Vendor',
    value: 'CONTRACTOR'
  },
  {
    label: 'Occupant',
    value: 'TENANT'
  }
];

export const createLoginTypes = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];
