import { locations } from '@shared/serializers/locations.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { templates } from '@shared/serializers/templates.serializer';
import { users } from '@shared/serializers/users.serializer';
import { assets } from '@shared/serializers/assets.serializer';

export const events = {
  type: 'event',
  attributes: [
    'user',
    'parent',
    'template',
    'location',
    'checklists',
    'label',
    'recurrence',
    'step',
    'startDate',
    'dueDate',
    'isAllowSubmissionAfterDue',
    'isWeekendExcluded',
    'isPaused',
    'isDeleted',
    'createdAt',
    'updatedAt',
    'address',
    'asset'
  ],
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  template: {
    ref: 'id',
    attributes: templates.attributes,
    included: false
  },
  location: {
    ref: 'id',
    attributes: locations.attributes,
    included: true
  },
  asset: [{
    ref: 'id',
    attributes: assets.attributes,
    included: true
  }],
  parent: {
    ref: 'id',
    attributes: [''],
    included: true
  },
  checklists: {
    ref: 'id',
    attributes: ['items', 'label', 'template', 'notes', 'reviewedAt', 'jobId'],
    included: false
  }
};

export const EventsSerializer = getSerializer(events);
