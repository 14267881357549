export enum TableColType {
  Ago,
  Avatar,
  Badge,
  Button,
  Date,
  Link,
  ExternalLink,
  Number,
  Pills,
  Score,
  Status,
  Text,
  Order,
  Pictures
}
