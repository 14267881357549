import { Injectable } from '@angular/core';
import { ICompanySetting } from '@shared/interfaces/company-setting.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  public companySettings: Subject<ICompanySetting> = new Subject<any>();

  constructor() {}

  public getCompanySettings(): Observable<any> {
    return this.companySettings.asObservable();
  }

  public toggleSetting(setting: any) {
    this.companySettings.next(setting);
  }
}
