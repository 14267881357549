import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { ITask } from '@shared/interfaces/task.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Task } from '@shared/models/task.model';
import { TasksSerializer } from '@shared/serializers/tasks.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IFilter } from '@shared/interfaces/filter.interface';
import { IDocument } from '@shared/interfaces/document.interface';
import { Document } from '@shared/models/documents';

@Injectable({
  providedIn: 'root'
})
export class TasksService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/tasks`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}

  public delete(id: string): Observable<ITask> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Task(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<ITask> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Task(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ITask[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: ITask) => new Task(a))
      ),
      catchError(errorHandler)
    );
  }

  public exportCvs(
    filters?: any
  ): Observable<Blob> {
    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    httpParams = httpParams.append('type', 'csv');
    const options: any = {
      params: httpParams
    };

    options.responseType = 'text';

    return this._http.get(this.endpoint+'/csv', options).pipe(
      map((res: any) => new Blob([res])),
      catchError(errorHandler)
    );
  }

  public patch(a: Partial<ITask>): Observable<ITask> {
    const data = TasksSerializer.serialize({
      ...a
    });

    return this._http.patch(`${this.endpoint}/${a.id}`, data).pipe(
      map((res: any) => new Task(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(a: Partial<ITask>): Observable<ITask> {
    const data = TasksSerializer.serialize({
      ...a,
      isSendEmailSelected: (a.user as any).isSendEmailSelected
    });

    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Task(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getDocuments(id: string): Observable<IDocument[]> {
    return this._http.get(`${this.endpoint}/${id}/documents`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((d: IDocument) => new Document(d))
      ),
      catchError(errorHandler)
    );
  }

  public getReport(filters?: any,): Observable<IDocument[]> {
    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint+'/report', { params: httpParams }).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public postDocument(file: File, filters: IFilter[]): Observable<IDocument> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let httpParams = new HttpParams();

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http
      .post(`${this._commonEnvironments.API_ENDPOINT}/pictures`, formData, {
        params: httpParams
      }).pipe(map((res: any) => new Document(deserializer.deserialize(res))));
  }
}
