import { getSerializer } from '@shared/serializers/serializer';

export const assetsCategory = {
  type: 'assetcategory',
  attributes: [
    'name',
  ],
};

export const AssetsCategorySerializer = getSerializer(assetsCategory);
