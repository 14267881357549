import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentsService } from '@shared/services/comments.service';
import moment from 'moment';
export interface ActiveCommentInterface {
  id: string;
  type: ActiveCommentTypeEnum;
}
export enum ActiveCommentTypeEnum {
  replying = 'replying',
  editing = 'editing',
}
export interface CommentInterface {
  id: string;
  body: string;
  username: string;
  userId: string;
  parentId: null | string;
  createdAt: string;
  taskId: string | any;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent {
  @Input() currentUserId!: string;
  @Input() taskId!: string;
  @Input() timezoneLocation: string;
  @Input() hasIcon?: boolean;

  comments: CommentInterface[] = [];
  activeComment: ActiveCommentInterface | null = null;

  constructor(private commentsService: CommentsService) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    this.getComments();
  }

  getComments() {
    this.commentsService.getComments(this.taskId).subscribe((comments) => {
      this.comments = comments.map( (item) => ({
          ...item,
          username: item.user.firstName + ' ' + item.user.lastName,
          userId: item.user.Id || item.user.id || item.user._id,
          createdAt: moment(item.createdAt).tz(this.timezoneLocation).format('lll')
        }));
    });
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment) => comment.parentId === null);
  }

  updateComment({
    text,
    commentId,
  }: {
    text: string;
    commentId: string;
  }): void {
    this.commentsService
      .updateComment(commentId, text)
      .subscribe(() => {
        this.getComments();
        this.activeComment = null;
      });
  }

  deleteComment(commentId: string): void {
    this.commentsService.deleteComment(commentId).subscribe(() => {
      this.getComments();
    });
  }

  setActiveComment(activeComment: ActiveCommentInterface | null): void {
    this.activeComment = activeComment;
  }

  addComment({
    text,
    parentId,
  }: {
    text: string;
    parentId: string | null;
  }): void {
    this.commentsService
      .createComment(text, parentId, this.taskId)
      .subscribe(() => {
        this.getComments();
        this.activeComment = null;
      });
  }

  getReplies(commentId: string): CommentInterface[] {
    return this.comments
      .filter((comment) => comment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }
}
