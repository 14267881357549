import { ISetting } from '@shared/interfaces/setting.interface';
import { IUser } from '@shared/interfaces/user.interface';

export class Setting implements ISetting {
  public id: string;
  public user: Partial<IUser>;

  public isNotificationEmailsEnabled: boolean;
  public isNotificationMobileEnabled: boolean;
  public isNotificationsEmergencyEnabled: boolean;
  public isTimezoneEnabled: boolean;
  public isNotificationsDigestEnabled: boolean;
  public isVacationModeEnabled: boolean;
  public isDemoEnabled: boolean;
  public theme: string;
  public language: string;

  constructor(partial: Partial<ISetting>) {
    Object.assign(this, partial);
  }
}
