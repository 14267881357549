import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IDocument } from '@shared/interfaces/document.interface';
import { IError } from '@shared/interfaces/error.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-documents',
  templateUrl: './modals.documents.component.html'
})
export class ModalsDocumentsComponent
  extends ModalDirective<IPicture>
  implements OnInit
{
  @Input() public openModal: Subject<any>;

  public title = String('MODALS.DOCUMENTS.TITLE');
  public description = String('MODALS.DOCUMENTS.DESCRIPTION');

  public entry: FormGroup;
  public errors: IError[] = [];
  public file: File;
  public upload: IDocument;
  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');

  public type = String('document');

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _locations: LocationsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((l: Partial<ILocation>) => {
      if (!!l) {
        this.entry.patchValue({
          location: {
            id: l.id
          }
        });
      }
    });
  }

  public onSubmit(files: NgxFileDropEntry[]) {
    this.errors = [];

    const id = this.entry.get('location').get('id').value;

    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.file = file;

          const url = `POST /locations/${id}/documents`;
          const filters: any = [
            {
              key: 'ownerId',
              value: id
            },
            {
              key: 'ownerType',
              value: 'Location'
            },
            {
              key: 'type',
              value: 'document'
            }
          ];

          this._locations.postDocument(file, filters).subscribe(
            (res: IDocument) => {
              this._logger.info(this.constructorName, url, res);

              this.resetModal();
            },
            (err: Error) => {
              this._logger.error(this.constructorName, url, err);
              this._toasts.error('Upload failed');
            }
          );
        });
      } else {
        this._toasts.error('Not a CSV file');
      }
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      location: this._fb.group({
        id: ['', Validators.required]
      })
    });
  }
}
