import { ICompany } from './company.interface';
import { ILibraryCategory } from './library-category.interface';
import { ILocation } from './location.interface';
import { IUser } from './user.interface';

export interface ILibrary {
  readonly id: string;
  readonly label: string;
  readonly comment: string;
  readonly type: string;
  readonly awsId: string;
  readonly company: Partial<ICompany>;
  readonly user: Partial<IUser>;
  readonly parentLocation: Partial<ILocation>;
  readonly subLocation: Partial<ILocation>;
  readonly isMobileVisible: boolean;
  readonly isDeleted: boolean;
  readonly createdAt: Date;
  readonly subCategory?: string;
  readonly category: ILibraryCategory;
  readonly assignedUsers?: Partial<IUser>[];
  readonly accessPermission: PermissionType;
  readonly url?: string;
}

export interface DocumentResponse {
  url: string
}

export enum PermissionType {
  'public',
  'myself',
  'company',
  'custom'
}