import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IEmployee } from '@shared/interfaces/employee.interface';
import { IError } from '@shared/interfaces/error.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IQRCode } from '@shared/interfaces/qrcode.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Template } from '@shared/models/template.model';
import { EmployeesService } from '@shared/services/employees.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { QRCodesService } from '@shared/services/qrcodes.service';
import { TemplatesService } from '@shared/services/templates.service';
import { ToastService } from '@shared/services/toast.service';
import { NgxSelectOption } from 'ngx-select-ex';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-qrcodes',
  templateUrl: './modals.qrcodes.component.html'
})
export class ModalsQRCodesComponent
  extends ModalDirective<IQRCode>
  implements OnInit
{
  @Input() public openModal: Subject<IQRCode>;

  public title = String('MODALS.QR_CODES.ADD.TITLE');
  public description = String('MODALS.QR_CODES.ADD.DESCRIPTION');
  public entry: FormGroup;

  public steps = [1, 2];
  public disableButtons = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public notifyees: Partial<IUser>[];

  public defaultLocationValue: string;
  public defaultTemplateValue: string;

  public errors: IError[] = [];

  public locationsFilters: Partial<IFilter>[] = [
    {
      key: 'parent',
      value: 'true'
    }
  ];

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _employees: EmployeesService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _qrcodes: QRCodesService,
    private readonly _toasts: ToastService,

    public readonly _locations: LocationsService,
    public readonly _templates: TemplatesService
  ) {
    super();

    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }

  ngOnInit(): void {
    this.createForm();
    this.getEmployees();
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /qrcodes/${id}`;

    this._qrcodes.delete(id).subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.entry.disable();

      let method = 'post';
      let url = `${method.toUpperCase()} /qrcodes`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /qrcodes/${value.id}`;
      }

      value.notifyees = (value?.notifyees || []).map((id: string) => ({ id }));

      this._qrcodes[method](value).subscribe(
        (res: IQRCode) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal();
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          const errors = err.errors;

          if (errors) {
            this.errors = errors;

            if (errors[0].detail) {
              this._toasts.error(errors[0].detail);
            }
          }

          this.entry.enable();
        }
      );
    }
  }

  public onMatOptionSelect(e: ITemplate | ILocation): void {
    const id = e.id;
    let type = 'location';

    if (e instanceof Template) {
      type = 'template';
    }
    this.entry.get(type).patchValue({
      id
    });
  }

  public selectChanges(e: NgxSelectOption[]) {
    const notifyees = e.map((ee: NgxSelectOption) => ee.value);
    this.entry.patchValue(notifyees);
  }

  public onGetValueFromAttributes(e: ITemplate | ILocation): string {
    return e.label;
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      label: ['', Validators.required],
      isDailyDigestEnabled: [false, Validators.required],
      step: [1, Validators.required],
      location: this._fb.group({
        id: ['', Validators.required]
      }),
      template: this._fb.group({
        id: ['', Validators.required]
      }),
      notifyees: ['']
    });
  }

  private openDialog() {
    this.openModal.subscribe((qr: IQRCode) => {
      if (!!qr) {
        this.defaultLocationValue = qr.location.label;
        this.defaultTemplateValue = qr.template.label;

        this.title = 'MODALS.QR_CODES.EDIT.TITLE';
        this.description = 'MODALS.QR_CODES.EDIT.TITLE';
        this.deleteKey = 'BUTTONS.DELETE';

        this.entry.patchValue({
          ...qr,
          notifyees: qr.notifyees.map((u: IUser) => u.id)
        });
      } else {
        this.defaultLocationValue = undefined;
        this.defaultTemplateValue = undefined;

        this.deleteKey = null;
      }
    });
  }

  private getEmployees() {
    const filters = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = 'GET /employees';
    this._employees.get(1, filters).subscribe(
      (res: IEmployee[]) => {
        this._logger.info(this.constructorName, url, res);
        this.notifyees = res.map((e: IEmployee) => e.user);
        this.openDialog();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }
}
