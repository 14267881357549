import { IChecklist } from '@shared/interfaces/checklist.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IMeter } from '@shared/interfaces/meter.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IUser } from '@shared/interfaces/user.interface';

export class Meter implements IMeter {
  id: string;
  parentLocation: ILocation;
  subLocation: ILocation;
  submittedBy: IUser;
  checklist: IChecklist;
  pictures: any[];
  status: string;
  meterType: string;
  meterUnit: string;
  value: string;
  isPaused: boolean;
  isValidated?: boolean;
  isDeleted: boolean;
  createdAt: Date;

  constructor(partial: Partial<any>) {
    Object.assign(this, partial);
  }

  public get buttonDownload() {
    return {
      icon: 'lnr-picture me-2',
      label: 'See Pictures',
      class: 'btn-light btn-sm',
      value: this.pictures
    };
  }

  public get isValidatedMeter() {

    let label = 'VALIDATE';
    let className = 'btn-primary btn-sm';

    if (this.isValidated) {
      label = 'VALIDATED';
      className = 'ohq-badge-success disabled badge-validated rounded-pill badge';
    } 

    return {
      label,
      class: className
    };
  }

  
}
