import { IAddress } from '@shared/interfaces/address.interface';
import { ICompany } from '@shared/interfaces/company.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Address } from '@shared/models/address.model';
import { User } from '@shared/models/user.model';

export class Location implements ILocation {
  public id: string;
  public _id?: string;
  public address: Partial<IAddress>;
  public company: Partial<ICompany>;
  public user: Partial<IUser>;
  public parent: Partial<ILocation>;
  public secondaryManagers?: Array<IUser>[];
  public subLocationManagers?: Array<IUser>[];

  public label: string;
  public notes: string;
  public ageOfRoof?: string;
  public subLocationsCount: number;
  public eventsCount: number;

  public isDeleted: boolean;

  public get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get typeaheadGroupField(): string {
    if (!this.parent) {
      return null;
    }

    return this.parent.label.toUpperCase();
  }

  constructor(partial: Partial<ILocation>) {
    Object.assign(this, {
      ...partial,
      address:
        typeof partial?.address === 'object'
          ? new Address(partial.address)
          : partial?.address,
      user:
        typeof partial?.user === 'object'
          ? new User(partial.user)
          : partial?.user
    });
  }
  pictureUrl?: string;
  picture?: Partial<IPicture>;
}
