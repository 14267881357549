import { events } from '@shared/serializers/events.serializer';
import { getSerializer } from '@shared/serializers/serializer';
import { users } from '@shared/serializers/users.serializer';

export const checklists = {
  type: 'checklist',
  attributes: [
    'antecedent',
    'event',
    'user',
    'reviewedBy',
    'submittedBy',
    'submittedAt',
    'items',
    'label',
    'template',
    'notes',
    'isLate',
    'reviewedAt',
    'jobId',
    'createdAt',
    'updatedAt'
  ],
  antecedent: {
    ref: 'id',
    attributes: ['items', 'notes'],
    included: false
  },
  event: {
    ref: 'id',
    attributes: events.attributes,
  },
  reviewedBy: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  user: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  submittedBy: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  }
};

export const ChecklistsSerializer = getSerializer(checklists);
