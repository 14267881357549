import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IDocument } from '@shared/interfaces/document.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { DocumentResponse, ILibrary } from '@shared/interfaces/library.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { Library } from '@shared/models/library.model';
import { LibrariesSerializer } from '@shared/serializers/libraries.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LibrariesService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/libraries`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) { }

  public delete(id: string): Observable<ILibrary> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Library(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<ILibrary> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Library(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ILibrary[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: ILibrary) => new Library(a))
      ),
      catchError(errorHandler)
    );
  }

  public getDocument(
    id?: string,
  ): Observable<Blob> {
    let url = this.endpoint;
    const httpParams = new HttpParams();
    url += `/${id}/document`;

    const options: any = {
      params: httpParams,
      responseType: 'blob'

    };

    return this._http.get(url, options).pipe(
      map((res: any) => new Blob([res])),
      catchError(errorHandler)
    );
  }

  public getDocumentUrl(id: string): Observable<DocumentResponse> {
    let url = this.endpoint
    url += `/${id}/document`;
    return this._http.get<DocumentResponse>(url);
  }

  public patch(a: Partial<ILibrary>): Observable<ILibrary> {
    const data = LibrariesSerializer.serialize({
      ...a
    });

    return this._http.patch(`${this.endpoint}/${a.id}`, data).pipe(
      map((res: any) => new Library(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(a: ILibrary): Observable<ILibrary> {
    const data = LibrariesSerializer.serialize(a);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Library(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public getLibraries(id: string): Observable<ILibrary[]> {
    return this._http.get(`${this.endpoint}/${id}/libraries`).pipe(
      map((res: any) =>
        deserializer.deserialize(res).map((d: ILibrary) => new Library(d))
      ),
      catchError(errorHandler)
    );
  }


  public postLibrary(file: File, filters: IFilter[]): Observable<ILibrary> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let httpParams = new HttpParams();
    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }
    return this._http
      .post(`${this._commonEnvironments.API_ENDPOINT}/libraries`, formData, {
        params: httpParams
      }).pipe(map((res: any) => new Library(deserializer.deserialize(res))));
  }

}

