import { getSerializer } from '@shared/serializers/serializer';

export const meters = {
  type: 'meter',
  attributes: [
    'eventLabel',
    'checklistId',
    'eventLocation',
    'value',
    'pictures',
    'createdAt',
    'submittedAt',
    'userFullName',
    'meterType',
    'isValidated',
    'meterUnit'
  ],
};

export const MeterSerializer = getSerializer(meters);