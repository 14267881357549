import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
export interface ActiveCommentInterface {
  id: string;
  type: ActiveCommentTypeEnum;
}
export enum ActiveCommentTypeEnum {
  replying = 'replying',
  editing = 'editing',
}
export interface CommentInterface {
  id: string;
  body: string;
  username: string;
  userId: string;
  parentId: null | string;
  createdAt: string;
  taskId: string | any;
}

@Component({
  selector: 'app-comment-form',
  templateUrl: './commentForm.component.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CommentFormComponent {
  @Input() submitLabel = String('');
  @Input() hasCancelButton = Boolean(false);
  @Input() initialText = String('');

  @Output()
  handleSubmit = new EventEmitter<string>();

  @Output()
  handleCancel = new EventEmitter<void>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    this.form = this.fb.group({
      title: [this.initialText, Validators.required],
    });
  }

  onSubmit(): void {
    this.handleSubmit.emit(this.form.value.title);
    this.form.reset();
  }
}
