import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentsFilter',
  pure: false
})
export class DocumentsFilterPipe implements PipeTransform {
  transform(items: any[], ids: any[]): any {
      if (!items || !ids) {
          return items;
      }
      return items.filter( (item: any) => ids.includes(item.id));
  }
}