import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { IReport } from '@shared/interfaces/reports.interface';
import { Report } from '@shared/models/report.model';
import { ReportsSerializer } from '@shared/serializers/reports.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/reports`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) {}


  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IReport[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    const data = this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((e: IReport) => new Report(e))
      ),
      catchError(errorHandler)
    );
    return data;
  }

  public find(id: string): Observable<IReport> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Report(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public generate(params: string): Observable<IReport> {
    return this._http.get(`${this.endpoint}/report?${params}`).pipe(
      map((res: any) => new Report(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public generateQRCodeReport(params: string): Observable<any> {
    return this._http.get(`${this.endpoint}/qrcode-report?${params}`).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public post(e: Partial<IReport>): Observable<IReport> {
    const data = ReportsSerializer.serialize(e);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Report(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }


}
