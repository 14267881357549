import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { appEnvironment } from '@app/environment/environment.app';
import { CommonEnvironmentsService } from '@shared/services/environments.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {
  public googleAnalytics: any = appEnvironment.GOOGLE_ANALYTICS;
  public crisp = String(appEnvironment.CRISP);

  constructor(
    @Inject(DOCUMENT) private readonly _document: any,
    private readonly _commonEnvironments: CommonEnvironmentsService
  ) {}

  public setScripts() {
    if (this._commonEnvironments.IS_PRODUCTION) {
      const head = this._document.getElementsByTagName('head')[0];

      const scriptGA = this._document.createElement('script');
      scriptGA.type = 'text/javascript';
      scriptGA.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalytics.trackingId}`;

      const scriptGATag = this._document.createElement('script');
      scriptGATag.scriptGATag = 'text/javascript';
      scriptGATag.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${this.googleAnalytics.trackingId}');`;

      const scriptCrisp = this._document.createElement('script');
      scriptCrisp.scriptGATag = 'text/javascript';
      scriptCrisp.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="${this.crisp}";(function(){d=document;s=d.createElement("script");
      s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;

      head.appendChild(scriptGA);
      head.appendChild(scriptGATag);
      head.appendChild(scriptCrisp);
    }
  }
}
