import moment from 'moment';
import tzlookup from 'tz-lookup';

const isTimezoneEnabled = () => {
    return JSON.parse(localStorage.getItem('app.onsitehq.co|usersettings'))?.setting?.isTimezoneEnabled
}

export const getTimezoneShort = (timeZone) => {
    return new Intl.DateTimeFormat('en-US', {
        timeZone: timeZone,
        timeZoneName: 'short'
    }).formatToParts(new Date())
        .find(part => part.type == "timeZoneName")
        .value;
}

export const getConvertedDate = (date, lat, lng) => {
    if (isTimezoneEnabled()) {
        return `${moment(date).format('lll')} ${getTimezoneShort(Intl.DateTimeFormat().resolvedOptions().timeZone)}`;
    }
    return `${moment(date).tz(tzlookup(lat, lng)).format('lll')} ${getTimezoneShort(tzlookup(lat, lng))}`;
}

export const getConvertedDateHours = (startDate, endDate, lat, lng) => {
    if (isTimezoneEnabled()) {
        return `${moment(startDate).format('LT')} to ${moment(endDate).format('LT')} ${getTimezoneShort(Intl.DateTimeFormat().resolvedOptions().timeZone)}`;
    }
    const timezone = tzlookup(lat, lng);
    return `${moment(startDate).tz(timezone).format('LT')} to ${moment(endDate).tz(timezone).format('LT')} ${getTimezoneShort(timezone)}`;
}

export const getConvertedDateByTimezone = (date, timezone) => {
    if (isTimezoneEnabled()) {
        return `${moment(date).format('lll')} ${getTimezoneShort(timezone)}`;
    }
    return `${moment(date).tz(timezone).format('lll')} ${getTimezoneShort(timezone)}`;
};