import { Injectable } from '@angular/core';
import { ILibrary } from '@shared/interfaces/library.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { LibrariesService } from '@shared/services/libraries.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationLibrariesService extends PaginationService<ILibrary> {
  private static readonly DEFAULT_IS_DELETED_FILTER: IFilter = {
    label: 'COMMON.ACTIVE',
    key: 'isDeleted',
    value: 'false'
  };

  private static readonly DEFAULT_PARENT_LOCATION_FILTER: IFilter = {
    key: 'parentLocation',
    value: null
  };

  private static readonly DEFAULT_SUB_LOCATION_FILTER: IFilter = {
    key: 'subLocation',
    value: null
  };

  private static readonly DEFAULT_CATEGORY_FILTER: IFilter = {
    key: 'category',
    value: null
  };

  private static readonly DEFAULT_POPULATE_CATEGORY: IFilter = {
    key: 'populateCategory',
    value: true
  };

  private _filtersScope: any;

  constructor(_paginable: LibrariesService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationLibrariesService.DEFAULT_IS_DELETED_FILTER,
      PaginationLibrariesService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationLibrariesService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationLibrariesService.DEFAULT_CATEGORY_FILTER,
      PaginationLibrariesService.DEFAULT_POPULATE_CATEGORY
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationLibrariesService.DEFAULT_IS_DELETED_FILTER,
      PaginationLibrariesService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationLibrariesService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationLibrariesService.DEFAULT_CATEGORY_FILTER,
      PaginationLibrariesService.DEFAULT_POPULATE_CATEGORY
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
