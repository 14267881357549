import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deserializer } from '@shared/helpers/deserializer.helper';
import { errorHandler } from '@shared/helpers/handle-error.helper';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IChecklist } from '@shared/interfaces/checklist.interface';
import { IIssue } from '@shared/interfaces/issue.interface';
import { IPaginable } from '@shared/interfaces/paginable.interface';
import { ITask } from '@shared/interfaces/task.interface';
import { Asset } from '@shared/models/asset.model';
import { Checklist } from '@shared/models/checklist.model';
import { Task } from '@shared/models/task.model';
import { AssetsSerializer } from '@shared/serializers/assets.serializer';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetsService implements IPaginable {
  private readonly endpoint: string = String(
    `${this._commonEnvironments.API_ENDPOINT}/assets`
  );

  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _http: HttpClient
  ) { }

  public delete(id: string): Observable<IAsset> {
    return this._http.delete(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Asset(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public find(id: string): Observable<IAsset> {
    return this._http.get(`${this.endpoint}/${id}`).pipe(
      map((res: any) => new Asset(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public get(
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IAsset[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(this.endpoint, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: IAsset) => new Asset(a))
      ),
      catchError(errorHandler)
    );
  }

  public patch(a: Partial<IAsset>): Observable<IAsset> {
    const data = AssetsSerializer.serialize({
      ...a
    });

    return this._http.patch(`${this.endpoint}/${a.id}`, data).pipe(
      map((res: any) => new Asset(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public post(a: IAsset): Observable<IAsset> {
    const data = AssetsSerializer.serialize(a);
    return this._http.post(this.endpoint, data).pipe(
      map((res: any) => new Asset(deserializer.deserialize(res))),
      catchError(errorHandler)
    );
  }

  public importCsv(data: any): Observable<IAsset> {
    return this._http.post(this.endpoint + '/import', data).pipe(
      map((res: any) => res),
      catchError(errorHandler)
    );
  }

  public getIssues(
    id: string,
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IIssue[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}/${id}/issues`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: IAsset) => new Asset(a))
      ),
      catchError(errorHandler)
    );
  }

  public getTasks(
    id: string,
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<ITask[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}/${id}/tasks`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((a: ITask) => new Task(a))
      ),
      catchError(errorHandler)
    );
  }

  public getChecklists(
    id: string,
    pageNumber?: number,
    filters?: any,
    onGetRawData?: (data: any) => void
  ): Observable<IChecklist[]> {
    let httpParams = new HttpParams();

    if (pageNumber) {
      httpParams = httpParams.set('page[number]', String(pageNumber));
    }

    if (filters) {
      filters.map((f) => {
        if (f.value) {
          httpParams = httpParams.append(f.key, f.value);
        }
      });
    }

    return this._http.get(`${this.endpoint}/${id}/checklists`, { params: httpParams }).pipe(
      tap((data: any) => onGetRawData && onGetRawData(data)),
      map((res: any) =>
        deserializer.deserialize(res).map((c: IChecklist) => new Checklist(c))
      ),
      catchError(errorHandler)
    );
  }

}

