import { Injectable } from '@angular/core';
import { IUser } from '@shared/interfaces/user.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {
  private topbarSource: Subject<any> = new Subject();

  public topbarCalled$ = this.topbarSource.asObservable();

  constructor() {}

  public updateUser(user: IUser) {
    this.topbarSource.next(user);
  }
}
