import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IActivity } from '@shared/interfaces/activity.interface';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { ActivitiesService } from '@shared/services/activities.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { TopbarService } from '@shared/services/topbar.service';
import { UpdatesService } from '@shared/services/updates.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit, OnDestroy {
  public topbarSubscription: Subscription;

  public user: IUser;

  public links: { icon: string; label: string; url: string }[] = [
    {
      icon: 'lnr-user text-muted',
      label: 'account',
      url: '/account'
    },
    {
      icon: 'lnr-exit text-danger',
      label: 'logout',
      url: '/logout'
    }
  ];

  public rows: IActivity[] = [];
  public isUpdateAvailable = Boolean(false);

  private readonly constructorName: string = String(this.constructor.name);

  private updateSubscription: Subscription;

  constructor(
    private readonly _ar: ActivatedRoute,
    private readonly _activities: ActivitiesService,
    private readonly _authentication: AuthenticationService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _logger: LoggerService,
    private readonly _router: Router,
    private readonly _topbar: TopbarService,
    private readonly _users: UsersService,
    private readonly _updates: UpdatesService
  ) {
    const urlUpdate = 'Update subscription';
    this.updateSubscription = this._updates.updatesCalled$.subscribe(
      (res: any) => {
        this._logger.info(this.constructorName, urlUpdate, res);

        this.isUpdateAvailable = res;
      },
      (err: any) => this._logger.error(this.constructorName, urlUpdate, err)
    );
  }

  ngOnInit(): void {
    this.getActivities();
    this.getUser();

    const url = 'Topbar subscription';
    this.topbarSubscription = this._topbar.topbarCalled$.subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);
        this.user = res;
      },
      (err: any) => this._logger.error(this.constructorName, url, err)
    );
  }

  ngOnDestroy(): void {
    this.topbarSubscription?.unsubscribe();
    this.updateSubscription?.unsubscribe();
  }

  public reload() {
    window.location.reload();
  }

  public getActivities(): void {
    const filters = [
      {
        key: 'startDate',
        value: moment()
          .tz(this._commonEnvironments.TZ)
          .subtract(1, 'week')
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      },
      {
        key: 'startDate',
        value: moment()
          .tz(this._commonEnvironments.TZ)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      },
      {
        key: 'limit',
        value: 5
      }
    ];

    const url = `GET /activities`;
    this._activities.get(1, filters).subscribe(
      (res: IActivity[]) => {
        this._logger.info(this.constructorName, url, res);
        this.rows = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public trackByFn(i): string {
    return i.id;
  }

  public goTo(a: IActivity) {
    if (a.key.indexOf('checklist') > -1) {
      this._router.navigate(['/checklists', a.trackableId], {
        relativeTo: this._ar
      });
    }
  }

  private getUser() {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    const id = payload.id;

    const url = `GET /users/${id}`;
    this._users.find(id).subscribe(
      (res: IUser) => {
        this._logger.info(this.constructorName, url, res);
        this.user = res;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        this._authentication.logout().subscribe();
      }
    );
  }
}
