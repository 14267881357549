import { NgModule } from '@angular/core';
import { ModalsDocumentsComponent } from '@app/shared/components/modals/documents/modals.documents.component';
import { ModalsEmployeesComponent } from '@app/shared/components/modals/employees/modals.employees.component';
import { ModalsNotesComponent } from '@app/shared/components/modals/notes/modals.notes.component';
import { ModalsReviewsComponent } from '@app/shared/components/modals/reviews/modals.reviews.component';
import { ModalsSignatureComponent } from '@app/shared/components/modals/signature/modals.signature.component';
import { NavbarComponent } from '@app/shared/components/navbar/navbar.component';
import { TopbarComponent } from '@app/shared/components/topbar/topbar.component';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SignaturePadModule } from 'ngx-signaturepad';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxUploaderModule } from 'ngx-uploader';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ModalsEmailsComponent } from './components/emails/modals.emails.component';
import { NgxEditorModule } from 'ngx-editor';

const components = [
  NavbarComponent,
  TopbarComponent,
  ModalsEmailsComponent,

  // Modals
  ModalsDocumentsComponent,
  ModalsEmployeesComponent,
  ModalsNotesComponent,
  ModalsReviewsComponent,
  ModalsSignatureComponent
];

const modules = [NgxUploaderModule, SignaturePadModule, UiSwitchModule, InfiniteScrollModule, FullCalendarModule, NgxEditorModule];

@NgModule({
  declarations: [...components],
  imports: [SharedModule, ...modules],
  exports: [SharedModule, ...modules, ...components]
})
export class AppSharedModule { }
