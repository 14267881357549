import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IError } from '@shared/interfaces/error.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modals-notes',
  templateUrl: './modals.notes.component.html'
})
export class ModalsNotesComponent
  extends ModalDirective<Partial<ILocation>>
  implements OnInit
{
  @Input() public openModal: Subject<Partial<ILocation>>;

  public title = String('MODALS.NOTES.EDIT.TITLE');
  public description = String('MODALS.NOTES.EDIT.DESCRIPTION');
  public submitKey = String('BUTTONS.SUBMIT');

  public entry: FormGroup;
  public disableButtons = Boolean(false);

  public deleteKey: string;

  public errors: IError[] = [];
  public notes: string;

  public modules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };

  private readonly constructorName: string = String(this.constructor.name);

  constructor(
    private readonly _locations: LocationsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.openModal.subscribe((l: ILocation) => {
      this.notes = l.notes;

      // Required to keep line breaks
      setTimeout(() => {
        this.entry.patchValue(l);
      });
    });
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if (valid) {
      this.disableButtons = true;
      this.entry.disable();

      const url = `PATCH /locations/${value.id}`;
      this._locations.patch(value).subscribe(
        (res: ILocation) => {
          this._logger.info(this.constructorName, url, res);

          this.resetModal(res);

          this.disableButtons = false;
          this.entry.enable();
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);

          this.disableButtons = false;
          this.entry.enable();
        }
      );
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      notes: ['']
    });
  }
}
