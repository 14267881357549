import { Component, HostListener, Input, OnInit } from '@angular/core';
import { CommentsService } from '@shared/services/comments.service';
import moment from 'moment';
export interface ActiveCommentInterface {
  id: string;
  type: ActiveCommentTypeEnum;
}
export enum ActiveCommentTypeEnum {
  replying = 'replying',
  editing = 'editing',
}
export interface CommentInterface {
  id: string;
  body: string;
  username: string;
  userId: string;
  parentId: null | string;
  createdAt: string;
  taskId: string | any;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'thread-comments',
  templateUrl: './thread-comments.component.html',
  styleUrls: ['./thread-comments.component.scss']
})
export class ThreadCommentsComponent {
  @Input() currentUserId!: string;
  @Input() timezoneLocation: string;
  items: string[]=[];
  isLoading=false;
  currentPage=1;

  comments: CommentInterface[] = [];
  activeComment: ActiveCommentInterface | null = null;
  page = Number(1);

  constructor(private commentsService: CommentsService) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {
    this.comments = [];
    this.getComments();
  }



  onScroll() {
    this.currentPage++;
    this.getComments();
  }

  getComments() {
    this.isLoading = true;
    this.commentsService.getMessages(this.currentPage).subscribe((comments) => {
      this.comments = [...this.comments, ...comments.map( (item) => ({
          ...item,
          username: item.user.firstName + ' ' + item.user.lastName,
          userId: item.user.Id || item.user.id || item.user._id
        }))];
        this.isLoading = false;
    });
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment) => comment.parentId === null);
  }

  updateComment({
    text,
    commentId,
  }: {
    text: string;
    commentId: string;
  }): void {
    this.commentsService
      .updateComment(commentId, text)
      .subscribe(() => {
        this.comments = [];
        this.currentPage = 1;
        this.getComments();
        this.activeComment = null;
      });
  }

  deleteComment(commentId: string): void {
    this.commentsService.deleteComment(commentId).subscribe(() => {
      this.comments = [];
      this.currentPage = 1;
      this.getComments();
    });
  }

  setActiveComment(activeComment: ActiveCommentInterface | null): void {
    this.activeComment = activeComment;
  }

  addComment({
    text,
    parentId,
    taskId
  }: {
    text: string;
    parentId: string | null;
    taskId: string | null;
  }): void {
    this.commentsService
      .createComment(text, parentId, taskId)
      .subscribe(() => {
        this.comments = [];
        this.currentPage = 1;
        this.getComments();
        this.activeComment = null;
      });
  }

  getReplies(commentId: string): CommentInterface[] {
    return this.comments
      .filter((comment) => comment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }
}
