import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { ICompany } from '@shared/interfaces/company.interface';
import { IContact } from '@shared/interfaces/contact.interface';
import { IEmail } from '@shared/interfaces/email.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { ContactsService } from '@shared/services/contacts.service';
import { EmailsService } from '@shared/services/emails.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { UploadOutput } from 'ngx-uploader';
import { NgxSelectOption } from 'ngx-select-ex';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { IPicture } from '@shared/interfaces/picture.interface';
import { PicturesService } from '@shared/services/pictures.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LibrariesService } from '@shared/services/libraries.service';
import { forkJoin } from 'rxjs';
import { Editor, Toolbar } from 'ngx-editor';
import { IPayload } from '@shared/interfaces/payload.interface';
import jwtDecode from 'jwt-decode';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { ILibrary } from '@shared/interfaces/library.interface';

@Component({
  selector: 'app-modals-emails',
  templateUrl: './modals.emails.component.html',
  styleUrls: ['./modals.emails.component.scss']

})
export class ModalsEmailsComponent
  extends ModalDirective<IEmail>
  implements OnInit {
  public title = String('EMAILS_TEMPLATES.ADD.TITLE');
  public description = String('EMAILS_TEMPLATES.ADD.TEMPLATE_DESCRIPTION');
  public config: any = {
    class: 'modal-lg'
  };

  public disableButtons = Boolean(false);
  public isEditing = Boolean(false);
  public isOpen = Boolean(false);
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public isLoading = Boolean(false);
  public locations: Partial<ILocation>[] = [];
  public contacts: Partial<IContact>[] = [];
  public subLocations: Partial<ILocation>[] = [];
  public libraryList: any[] = [];
  public subLocationsFilters: any[] = [
    {
      key: 'pagination',
      value: false
    }
  ];

  public entry: FormGroup;

  public modules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };
  public uploadImages: any[] = [];
  public file: File;
  public files: NgxFileDropEntry[] = [];
  public documentList: any[] = [];
  public documentListLabels: any[] = [];
  public filesToUpload: NgxFileDropEntry[] = [];
  public isUploading = Boolean(false);
  public showDeviceFiles = Boolean(false);
  public fgSelected: FormGroup;
  public showFields = new FormControl(false);
  public userId: string;
  public pictureSelected: string;
  public items: any[] = [];
  private blob: Blob;
  private readonly constructorName: string = String(this.constructor.name);
  editor: Editor;
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private readonly _emails: EmailsService,
    private readonly _logger: LoggerService,
    private readonly _fb: FormBuilder,
    private readonly _toasts: ToastService,
    public readonly _locations: LocationsService,
    private readonly _contacts: ContactsService,
    public readonly _libraries: LibrariesService,
    private readonly _pictures: PicturesService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    public dom: DomSanitizer
  ) {
    super();
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
    this.onGetValueFromAttributesLocation = this.onGetValueFromAttributesLocation.bind(this);
    this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
    this.deletePicture = this.deletePicture.bind(this);

  }

  ngOnInit(): void {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    this.userId = payload.id;
    this.editor = new Editor();
    this.getContacts();
    this.getDocuments();
    this.getParentLocations();
    this.openModal.subscribe((e: IEmail) => {
      this.isOpen = false;

      if (e) {
        this.title = 'EMAILS_TEMPLATES.EDIT.TITLE';
        this.description = 'EMAILS_TEMPLATES.EDIT.DESCRIPTION';
        this.deleteKey = 'BUTTONS.DELETE';
        this.isEditing = true;

        this.entry.patchValue({ ...e, replyTo: e.replyTo?.join() || '', body: e.body, documentList: e.attachments.map(item => item?.id) || []});
        // this.libraryList = e.attachments || [];
        
      } else {
        this.title = 'EMAILS_TEMPLATES.ADD.TITLE';
        this.description = 'EMAILS_TEMPLATES.ADD.DESCRIPTION';
        this.deleteKey = null;
        this.isEditing = false;
      }

      // Needed for ngx-quill html <p> issue
      // https://stackoverflow.com/questions/54967663/quilljs-merges-subsequent-p-tags-into-one-with-angular-7-ngx-quill
      
    });

    this.createForm();
  }

  ngOnDestroy() {
    this.editor.destroy();
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
  }

  public deletePicture(): void {
    const url2 = `DELETE /pictures/${this.pictureSelected}`;
    this._pictures.delete(this.pictureSelected).subscribe(
      (res: IPicture) => {
        this._logger.info(this.constructorName, url2, res);
        this.isLoading = true;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url2, err);
        this._toasts.error('Tasks deletion failed');
      }
    );
  }

  public removePicture(i: number) {
    this.filesToUpload.splice(i, 1);
    this.files.splice(i, 1);
    this.uploadImages.splice(i, 1);
  }

  public openConfirmModal(fg: FormGroup, id: string) {
    this.fgSelected = fg;
    this.pictureSelected = id;
    this.deletePicture();

    // this.openDeleteModal.next();
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    value.attachments = value.documentList;
    if(!value.isCheckboxSelected) {
      value.cta = '';
      value.url = '';
    }

    if(!value.isReplyCheckboxSelected) {
      value.replyTo = '';
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (value.replyTo && value.replyTo.split(',').length > 0) {
        const emailsArray = value.replyTo.split(',');
        const invalidEmails = emailsArray.filter(email => !emailRegex.test(email.trim()));
        
        if (invalidEmails.length > 0) {
            return this._toasts.error('Sorry! Enter valid emails!');
        }else {
          value.replyTo = emailsArray;
        }
      
      } else {
        this._toasts.error('Please, type the emails separated by commas.');
      }
    }
    
    if (valid) {
      let method = 'post';
      let url = `${method.toUpperCase()} /emails`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /emails/${value.id}`;
      }
      this.entry.disable();
      this.disableButtons = true;

        let promises = [];
        this.filesToUpload.forEach((f) => {
          if (f.fileEntry.isFile) {
            const fileEntry = f.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              const filters: any = [
                {
                  key: 'label',
                  value: file.name
                },
                {
                  key: 'assignedUsers',
                  value: [this.userId]
                }
              ];
              promises.push(this._libraries.postLibrary(file, filters));
            });
          }
        });
        if (this.filesToUpload.length > 0) {
          this.isUploading = true;
          forkJoin(promises).subscribe(
            (resDocs) => {
              this.isUploading = false;
              promises = [];
              if (!value?.attachments) {
                value.attachments = [];
              }
              value.attachments = [...resDocs.map((item: ILibrary) => item.id), ...value.documentList.map((item: ILibrary) => item?.id || item )];
              this.entry.patchValue({ documentList: [...resDocs.map((item: ILibrary) => item), ...value.documentList.map((item: ILibrary) => item )] });
              this.entry.disable();
              this.disableButtons = true;
              
              this._emails[method](value).subscribe(
                (res: IEmail) => {
                  this._logger.info(this.constructorName, url, res);
  
                  this._toasts.success('Email Saved!');
                  this.entry.enable();
                  this.disableButtons = false;
                  this.resetModal();
                  
                },
                (err: any) => {
                  this._logger.error(this.constructorName, url, err);
                  if (err.errors) {
                    this.errors = err.errors;
                  }
                  this.entry.enable();
                  this.disableButtons = false;
  
                  this._toasts.error('An error has occurred!');
                }
              );
            },
            (error) => {
              this._toasts.error('An error has occurred when attaching files');
              console.error(error.message);
            }
          );
        } else {
          if (!value?.attachments) {
            value.attachments = [];
          }
          value.attachments = [...value.documentList.map((item: ILibrary) => item?.id || item )];
          this.entry.disable();
          this.disableButtons = true;
          this.isLoading = true;
          
          this._emails[method](value).subscribe(
            (res: IEmail) => {
              this._logger.info(this.constructorName, url, res);
  
              this._toasts.success('Email Saved!');
              this.isLoading = false
  
              this.entry.enable();
              this.disableButtons = false;
              this.resetModal();
            },
            (err: any) => {
              this._logger.error(this.constructorName, url, err);
  
              if (err.errors) {
                this.errors = err.errors;
              }
              this.isLoading = false;
  
              this.entry.enable();
              this.disableButtons = false;
  
              this._toasts.error('An error has occurred!');
            }
          );
        }
    }
  }

  public isImage(filename) {
    return filename.split('.').pop() === 'jpg' ||
      filename.split('.').pop() === 'png' ||
      filename.split('.').pop() === 'jpeg'
      ? true
      : false;
  }


  public isImageType(type) {
    const imageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp'
    ];
    return imageTypes.includes(type);
  }

  public onUploadOutput(output: UploadOutput) {
    switch (output.type) {
      case 'addedToQueue':
        this._logger.info(this.constructorName, 'File added to queue', output);
        const file = output.file;
        this.blob = new Blob([file.nativeFile], { type: file.type });

        this.startUpload();
        break;
    }
  }

  public startUpload(): void {
    if (this.blob) {
      this.isUploading = true;
      const filters: any = [
        {
          key: 'ownerType',
          value: 'User'
        },
        {
          key: 'type',
          value: 'profile'
        }
      ];
      const url = `POST /pictures`;
      this._pictures.post(filters, this.blob).subscribe(
        (res: IPicture) => {
          this._logger.info(this.constructorName, url, res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
          this._toasts.success('User update failed');
        }
      );
    }
  }

  public openUpload() {
    const el = document.getElementsByClassName('upload-files-tasks')[0] as any;
    el?.click();
  }

  public removeUplodedDocument(id: string) {
    this.entry.patchValue({
      documents: [...this.entry.get('documents')?.value?.filter(item => item !== id)],
      documentList: [...this.entry.get('documentList')?.value?.filter(item => item !== id)],
    });
  }

  public getDocuments() {
    this._libraries
      .get(1, [
        {
          key: 'limit',
          value: 300
        }
      ])
      .subscribe((res) => {
        this.isLoading = false;
        this.documentList = res;
        this.documentListLabels = res.map((res2) => res2.label);
      });
  }

  public onGetValueFromAttributes(e: ICompany) {
    return e.name;
  }

  public onDismiss() {
    this.errors = [];

    this.entry?.reset();
    this.setDefaultValues();
  }

  private setDefaultValues() {
    this.disableButtons = false;

    this.items = [];
    this.files = [];
    this.documentListLabels = [];
    this.uploadImages = [];
    this.libraryList = [];
    this.uploadImages = [];
    this.filesToUpload = [];
    this.entityForm.reset();
    this.errors = [];
    this.createForm();
    this.entry.enable();
  }

  public getContacts() {
    this._contacts.get(1, [{ key: 'userType', value: 'TENANT' }, { key: 'pagination', value: 'false' }]).subscribe(
      (res: IContact[]) => {
        this.contacts = res;
        this.contacts.unshift({
          email: 'All Occupants',
        });
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
        console.log('err', err);
      }
    );
  }

  public onGetValueFromAttributesLocation(e: ILocation) {
    return e.label;
  }

  public onMatOptionSelectSubLocation(e: NgxSelectOption[]): void {
    let subLocations;
    if (e.length > 0 && e[e.length - 1].value === '-1') {
      subLocations = ['-1'];
    } else {
      subLocations = e.filter(item => item.value !== '-1').map((ee: NgxSelectOption) => ee.value);
    }

    setTimeout(() => {
      this.entry.patchValue({ subLocations });
    }, 500);

  }

  public selectChangesLocations(e: NgxSelectOption[]) {

    let locations;
    if (e.length > 0 && e[e.length - 1].value === '-1') {
      locations = ['-1'];
    } else {
      locations = e.filter(item => item.value !== '-1').map((ee: NgxSelectOption) => ee.value);
    }

    setTimeout(() => {
      this.entry.patchValue({ locations });
      this.getSubLocation();

    }, 500);

  }

  public selectChangesOccupant(e: any[]) {
    let occupants;
    if (e.length > 0 && e[e.length - 1].value === 'All Occupants') {
      occupants = ['All Occupants'];
    } else {
      occupants = e.filter(item => item.value !== 'All Occupants').map((ee: any) => ee.value);
    }
    setTimeout(() => {
      this.entry.patchValue({ occupants });
    }, 500);

  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.filesToUpload = [...this.filesToUpload, ...files];
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size < 20000000) {
            this.file = file;
            this.files = [...this.files, this.file as any];
            this.uploadImages.push(URL.createObjectURL(file));
            if (this.entry.get('fileNames').value) {
              this.entry.patchValue({
                fileNames: [...this.entry.get('fileNames').value, file.name]
              });
            } else {
              this.entry.patchValue({
                fileNames: [file.name]
              });
            }
          } else {
            this._toasts.error(`Sorry! The file named "${file.name}"
               exceeds the maximum allowable size of 20MB. Please upload a smaller file to proceed.`);
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      files: [''],
      fileNames: [''],
      documentList: [[], []],
      documents: [[], []],
      subject: ['', [Validators.required]],
      // title: ['', [Validators.required]],
      body: new FormControl(''),
      isCheckboxSelected: [false],
      replyTo: ['', []],
      isReplyCheckboxSelected: [false],
      cta: [''],
      url: [''],
    });
  }

  private getSubLocation() {

    if (this.entry.get('locations').value?.length > 0) {

      this.subLocationsFilters = [
        {
          key: 'parent',
          value: this.entry.get('locations').value[0] === '-1' ?
            this.locations.map(item => item.id.toString()).slice(1) : this.entry.get('locations').value.join(',')
        },
        {
          key: 'pagination',
          value: 'false'
        },
      ];
    }

    const url = `GET /locations`;
    this._locations.getSubLocations(1, this.subLocationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.subLocations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));

        this.subLocations.unshift({
          id: '-1',
          label: 'All Sub-Locations'
        });

        if (this.entry.get('subLocations').value.length < 1) {
          this.entry.patchValue({ subLocations: ['-1'] });
        }

        setTimeout(() => {
          this.entry.patchValue({ occupants: ['All Occupants'] });
        }, 500);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private getParentLocations() {
    const locationsFilters: any[] = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = `GET /locations`;
    this._locations.get(1, locationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.locations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));
        this.locations.unshift({
          id: '-1',
          label: 'All Locations'
        });
        this.entry.patchValue({ locations: ['-1'] });
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /emails/${id}`;

    this._emails.delete(id).subscribe(
      (res: IEmail) => {
        this._logger.info(this.constructorName, url, res);

        this.resetModal();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        if (err.errors) {
          this.errors = err.errors;
        }
      }
    );
  }
}
