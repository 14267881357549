import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreRoutingModule } from '@app/core/core-routing.module';
import { AppSharedModule } from '@app/shared/shared.module';
import { environment } from '@environment/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AirbrakeErrorHandler } from '@shared/handlers/airbrake-error.handler';
import { InterceptorService } from '@shared/services/interceptor.service';
import { CustomPreloadingStrategy } from '@shared/strategies/custom.preloading.strategy';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ChartModule } from 'angular-highcharts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SidebarModule } from 'ng-sidebar';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgxSelectModule } from 'ngx-select-ex';
import { SignaturePadModule } from 'ngx-signaturepad';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxWebstorageModule } from 'ngx-webstorage';

export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

const config: SocketIoConfig = {
  url: environment.SOCKET_ENDPOINT,
  options: {}
};

@NgModule({
  imports: [
    AppSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgxUploaderModule,
    CoreRoutingModule,
    ChartModule,
    GoogleMapsModule,
    SignaturePadModule,
    NgxSelectModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
    PhotoGalleryModule.forRoot({}),
    SidebarModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PopoverModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    SocketIoModule.forRoot(config),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgCircleProgressModule.forRoot({
      animation: false,
      renderOnClick: false,
      titleColor: '#000000',
      innerStrokeColor: 'rgba(0, 0, 0, 0)',
      space: -3.5,
      outerStrokeWidth: 3,
      showSubtitle: false,
      outerStrokeLinecap: 'round',
      lazy: false
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'app.onsitehq.co'
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      toastClass: 'ngx-toastr ohq-toastr',
      preventDuplicates: true
    }),
    LoggerModule.forRoot({
      level: environment.IS_PRODUCTION
        ? NgxLoggerLevel.ERROR
        : NgxLoggerLevel.DEBUG
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#21CE99',
      defaultBgColor: '#FF2721',
      defaultBoColor: 'rgba(0, 0, 0, 0.1)'
    }),
  ],
  exports: [RouterModule],
  providers: [
    CustomPreloadingStrategy,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {}
