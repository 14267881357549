import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { IAsset } from '@shared/interfaces/asset.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { Location } from '@shared/models/location.model';
import { AssetsService } from '@shared/services/assets.service';
import { EmployeesService } from '@shared/services/employees.service';
import { LocationsService } from '@shared/services/locations.service';
import { LoggerService } from '@shared/services/logger.service';
import { ToastService } from '@shared/services/toast.service';
import { forkJoin, noop, Subject } from 'rxjs';
import { UploaderOptions, UploadInput } from 'ngx-uploader';
import { uploaderOptions } from '@shared/helpers/upload.helper';
import { ContactsService } from '@shared/services/contacts.service';
import { AddressesService } from '@shared/services/addresses.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AssetCategorysService } from '@shared/services/assets-categories.service';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { ITask } from '@shared/interfaces/task.interface';
import { Router } from '@angular/router';
import { PaginationAssetsService } from '@shared/services/pagination/pagination.assets.service';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ILibrary } from '@shared/interfaces/library.interface';
import { LibrariesService } from '@shared/services/libraries.service';
import moment from 'moment';

@Component({
  selector: 'app-modals-assets',
  templateUrl: './modals.assets.component.html',
  styleUrls: ['./modals.assets.component.scss'],
})
export class ModalsAssetsComponent
  extends ModalDirective<IAsset>
  implements OnInit
{
  @Input() public openModal: Subject<IAsset>;
  @Input() public afterClose?: () => any = noop;
  @Input() public afterSubmit: (any) => any = noop;

  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  public options: UploaderOptions = uploaderOptions;
  public openTasksModal: Subject<ITask> = new Subject();

  public defaultParentLocationValue: string;
  public defaultSubLocationValue: string;
  public defaultCategoryValue: string;
  public title = String('MODALS.ASSETS.ADD.TITLE');
  public description = String('MODALS.ASSETS.ADD.DESCRIPTION');

  public documentList: any[] = [];
  public documentListLabels: any[] = [];
  public categories: IAssetCategory[] = [];
  public submitKey = String('BUTTONS.SUBMIT');
  public deleteKey: string;
  public disableButtons = Boolean(false);
  public openDeleteModal: Subject<boolean> = new Subject();
  public isEditing = Boolean(false);
  public id = String('');
  public assetForm: any = {};
  public isTenant = Boolean(false);
  public fgSelected: FormGroup;
  public categoryFilters: any[] = [];
  public uploadImages: any[] = [];
  public items: any[] = [];
  public libraryList: any[] = [];
  public file: File;
  public files: NgxFileDropEntry[] = [];
  public filesToUpload: NgxFileDropEntry[] = [];
  public showDeviceFiles = Boolean(false);
  public locationsFilters: any[] = [
    {
      key: 'parent',
      value: false
    }
  ];
  public subLocationsFilters: any[] = [
    {
      key: 'parent',
      value: true
    }
  ];

  public employeesFilters: IFilter[] = [
    {
      key: 'role',
      value: '3'
    }
  ];
  public isLoading = Boolean(true);
  public isUploading = Boolean(false);
  public isDisabled = Boolean(false);
  public loaded = Boolean(false);
  public entry: FormGroup;
  public locations: ILocation[] = [];
  public timezone = String('');
  public timezoneLocation = String('');
  public currentUserId = String('');

  public bsConfig: any = {
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minMode: 'year',
    maxDate: moment().toDate()
  };
  public config: any = {
    class: 'modal-lg'
  };
  public closeModal: Subject<any> = new Subject<any>();

  private readonly constructorName: string = String(this.constructor.name);
  private blob: Blob;

  constructor(
    public readonly _locations: LocationsService,
    public readonly _employees: EmployeesService,
    public readonly _contacts: ContactsService,
    public readonly _assets: AssetsService,
    public readonly _assetsCategory: AssetCategorysService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    private readonly _toasts: ToastService,
    public readonly _addresses: AddressesService,
    private readonly _paginationAssets: PaginationAssetsService,
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _router: Router,
    public readonly _libraries: LibrariesService,
    public dom: DomSanitizer
  ) {
    super();
    this.onMatOptionSelect = this.onMatOptionSelect.bind(this);
    this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
    this.onGetCategoryName = this.onGetCategoryName.bind(this);
    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
  }


  ngOnInit(): void {
    this.getCategories();
    this.getDocuments();
  }

  public getDocuments() {
    this.isLoading = true;
    // TODO FIX USE THE RIGHT FILTERS
    this._libraries
      .get(1, [
        {
          key: 'limit',
          value: 300
        }
      ])
      .subscribe((res) => {
        this.isLoading = false;
        this.documentList = res;
        this.documentListLabels = res.map((res2) => res2.label);
      });
  }

  public openUpload() {
    const el = document.getElementsByClassName('upload-files-tasks')[0] as any;
    el?.click();
  }

  public whenModalClose(type: string): void {
    if (type === 'SUBMIT') {
      this.errors = [];
      this.entityForm.ngSubmit.emit();
    } else if (type === 'DELETE') {
      this.delete();
    }
    this.id = '';
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if(valid) {
      let method = 'post';
      let url = `${method.toUpperCase()} /assets`;
      if (value.id) {
        method = 'patch';
        url = `${method.toUpperCase()} /assets/${value.id}`;
      }
      value = this.entry.getRawValue();
      if (typeof value.year !== 'number' && !!value.year){
        value.year = moment(value.year).format('Y');
      }
      if (value.year === 'Invalid date'){
        delete value.year;
      }

      let promises = [];
      this.filesToUpload.forEach((f) => {
        if (f.fileEntry.isFile) {
          const fileEntry = f.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const filters: any = [
              {
                key: 'label',
                value: file.name
              }
            ];
            promises.push(this._libraries.postLibrary(file, filters));
          });
        }
      });
      if(this.filesToUpload.length > 0) {
        forkJoin(promises).subscribe(
          (resDocs) => {
            this.isUploading = false;
            promises = [];

            if(!value?.documents) {
              value.documents = [];
            }

            value.documents = [...value?.documents, ...resDocs.map( (item: ILibrary) => item.id), ...value.documentList];
            this.createAndUpdateAsset(value, method, url);
          },
          (error) => {
            this._toasts.error('Upload has failed!');
            console.error(error.message);
          }
        );
      }else {
        if(!value?.documents) {
          value.documents = [];
        }
        value.documents = [...value?.documents, ...value.documentList];
        this.createAndUpdateAsset(value, method, url);
      }
    }
  }

  public createAndUpdateAsset(value, method, url) {
    this.disableButtons = true;
    this.entry.disable();

    this._assets[method](value).subscribe(
      (res: IAsset) => {
        this._logger.info(this.constructorName, url, res);
        this.entry.enable();
        this.entry.reset();
        this.disableButtons = false;
        this.id = res.id;
        if(method === 'post') {
          this._toasts.success('Asset successfully created!');
        } else if(method === 'patch') {
          this._toasts.success('Asset successfully updated!');
        }

        this._paginationAssets.reloadPage(1);
        this.createForm();
        this.closeModal.next();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
        this.disableButtons = false;
      }
    );
  }

  public isImage(filename) {
    return filename.split('.').pop() == 'jpg' ||
      filename.split('.').pop() == 'png' ||
      filename.split('.').pop() == 'jpeg'
      ? true
      : false;
  }

  public isImageType(type) {
    const imageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp'
    ];
    return imageTypes.includes(type);
  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.filesToUpload = [...this.filesToUpload, ...files];
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if(file.size < 20000000) {
            this.file = file;
            this.files = [...this.files, this.file as any];
            this.uploadImages.push(URL.createObjectURL(file));
            if (this.entry.get('fileNames').value) {
              this.entry.patchValue({
                fileNames: [...this.entry.get('fileNames').value, file.name]
              });
            } else {
              this.entry.patchValue({
                fileNames: [file.name]
              });
            }
          } else {
            this._toasts.error(`Sorry! The file named "${file.name}" exceeds the maximum allowable size of 20MB. Please upload a smaller file to proceed.`);
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  public onMatOptionSelect(e: any): void {
    if(e) {
      this.entry.get('parentLocation').patchValue({
        id : e.id
      });
    }
    this.subLocationsFilters = [
      {
        key: 'parent',
        value: true
      },
      {
        key: 'locations',
        value: e.id
      },
    ];
  }

  public onMatOptionSelectSubLocation(e: any): void {
    if(e) {
      this.entry.get('subLocation').patchValue({
        id : e.id
      });
    }
  }

  public onMatOptionSelectCategory(e: any): void {
    if(e) {
      this.entry.get('category').patchValue({
        id : e.id
      });
    }
  }

  public onDismiss() {
    this.afterClose();
    this.id = '';
  }

  public onGetValueFromAttributes(e: any) {
    if (e instanceof Location) {
      return `${e.label} ${e.parent ? e.parent.label : ''}`;
    }
  }

  public onGetCategoryName(e: any) {
      return `${e.name}`;
  }

  public removePicture(i: number) {
    this.filesToUpload.splice(i, 1);
    this.files.splice(i, 1);
    this.uploadImages.splice(i, 1);
  }

  public removeUplodedDocument(id: string) {
    this.libraryList = this.libraryList.filter( item => item.id !== id);
    this.documentList = this.documentList.filter( item => item.id !== id);
    this.entry.patchValue({
      documents: [...this.entry.get('documents').value.filter( item => item !== id)],
      documentList: [...this.entry.get('documentList').value.filter( item => item !== id)],
    });
  }

  public getCategories() {
    const url = `GET /assets-categories`;
    this._assetsCategory.get(1, []).subscribe(
      (res: IAssetCategory[]) => {
        this.categories = res;
        this.createForm();
        this.openModal.subscribe((a: IAsset) => {
          this.assetForm = a;
          if (a) {
            if(a.id) {
              this.libraryList = a?.documents || [];
              this.id = a.id;
              this.title = 'MODALS.ASSETS.EDIT.TITLE';
              this.description = 'MODALS.ASSETS.EDIT.DESCRIPTION';
              this.deleteKey = 'BUTTONS.DELETE';
              this.id = a.id;
            }
            if(a.subLocation) {
              this.onMatOptionSelectSubLocation(a.subLocation.id);
              this.defaultSubLocationValue = a.subLocation.label;
            }
            if(a.parentLocation) {
              this.onMatOptionSelect(a.parentLocation.id);
              this.defaultParentLocationValue = a.parentLocation.label;
            }

            this.entry.patchValue({
              id: a.id,
              name: a.name,
              description: a.description,
              modelName: a.modelName,
              serial: a.serial,
              tag: a.tag,
              make: a.make,
              parentLocation: a.parentLocation,
              documents: a?.documents?.map( (item: any) => item?.id),
              subLocation: a.subLocation,
              category: a.category,
              year: a.year,
              lifeExpectancy: a.lifeExpectancy,
            });

            this.loaded = true;
          } else {
            this.createForm();

            this.title = 'MODALS.ASSETS.ADD.TITLE';
            this.description = 'MODALS.ASSETS.ADD.DESCRIPTION';
            this.defaultSubLocationValue = '';
            this.defaultParentLocationValue = '';
            this.defaultCategoryValue = '';
            this.deleteKey = null;
          }
          this.loaded = true;
        });
      },
      (err) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  protected createForm() {
    this.entry = this._fb.group({
      id: [''],
      files: [''],
      fileNames: [''],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      modelName: ['', []],
      tag: ['', []],
      serial: ['', []],
      make: [''],
      year: [''],
      lifeExpectancy: [''],
      documentList: [[], []],
      documents: [[], []],
      parentLocation: this._fb.group({
        id: ['', []]
      }),
      category: this._fb.group({
        id: ['']
      }),
      subLocation: this._fb.group({
        id: ['', []]
      }),
    });
  }

  private delete() {
    const id = this.entry.get('id').value;
    const url = `DELETE /assets/${id}`;

    this._assets.delete(id).subscribe(
      (res: IAsset) => {
        this._logger.info(this.constructorName, url, res);
        this._paginationAssets.reloadPage(1);
        this._router.navigate(['/assets']);
        this.reset();
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);

        const errors = err.errors;
        if (errors) {
          this.errors = errors;

          if (errors[0].detail) {
            this._toasts.error(errors[0].detail);
          }
        }

        this.entry.enable();
      }
    );
  }

  private reset() {
    this.disableButtons = false;
    this.entry.enable();
    this.resetModal();
  }

}
