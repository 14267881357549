import { environment } from '@environment/environment';
import { ICompany } from '@shared/interfaces/company.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { ISetting } from '@shared/interfaces/setting.interface';

export class Company implements ICompany {
  public id: string;
  public name: string;
  public logo: Partial<IPicture>;
  public settings: ISetting;
  public isDeleted: boolean;

  get logoUrl(): string {
    return this.logo
      ? `${this.logo.url}`
      : `../../../assets/images/pictures/company.png`;
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  constructor(partial: Partial<ICompany>) {
    Object.assign(this, partial);
  }
}
