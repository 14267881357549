import { librariesCategory } from './libraries-category.serializer';
import { locations } from './locations.serializer';
import { getSerializer } from './serializer';
import { users } from './users.serializer';

export const libraries = {
  type: 'library',
  attributes: [
    'label',
    'comment',
    'type',
    'awsId',
    'company',
    'user',
    'parentLocation',
    'subLocation',
    'isMobileVisible',
    'isDeleted',
    'createdAt',
    'assignedUsers',
    'category',
    'accessPermission',
    'url',
    'subCategory'
  ],
  parentLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  subLocation: {
    ref: 'id',
    attributes: locations.attributes,
    included: false
  },
  assignedUsers: {
    ref: 'id',
    attributes: users.attributes,
    included: false
  },
  category: {
    ref: 'id',
    attributes: librariesCategory.attributes,
    included: false
  },
};

export const LibrariesSerializer = getSerializer(libraries);
