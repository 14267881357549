import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { ICompany } from '@shared/interfaces/company.interface';
import { ILibrary, PermissionType } from '@shared/interfaces/library.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { environment } from '@environment/environment';
import { ILibraryCategory } from '@shared/interfaces/library-category.interface';

export class Library implements ILibrary {
  readonly id: string;
  readonly label: string;
  readonly comment: string;
  readonly type: string;
  readonly awsId: string;
  readonly company: Partial<ICompany>;
  readonly user: Partial<IUser>;
  readonly parentLocation: Partial<ILocation>;
  readonly subLocation: Partial<ILocation>;
  readonly isMobileVisible: boolean;
  readonly isDeleted: boolean;
  readonly subCategory?: string;
  readonly createdAt: Date;
  readonly category: ILibraryCategory;
  readonly assignedUsers?: Partial<IUser>[];
  readonly accessPermission: PermissionType;
  readonly url?: string;

  constructor(
    partial: Partial<ILibrary>) {
    Object.assign(this, {
      ...partial,
      parentLocation:
        partial?.parentLocation !== null &&
          typeof partial?.parentLocation === 'object'
          ? new Location(partial.parentLocation)
          : partial?.parentLocation,
      subLocation:
        partial?.subLocation !== null &&
          typeof partial?.subLocation === 'object'
          ? new Location(partial.subLocation)
          : partial?.subLocation,
    });
  }

  public get buttonDownload() {
    return {
      icon: 'lnr-download me-2',
      label: 'Download',
      class: 'btn-light btn-sm',
      awsId: this.awsId
    };
  }

  public get openLink() {
    const imageTypes = ['image/jpeg', 'image/jpg', 'image/png','image/gif','image/bmp', 'application/pdf', 'application/vnd.ms-excel', 'application/msexcel', 'application/x-msexcel', 'application/x-ms-excel', 'application/x-excel', 'application/x-dos_ms_excel', 'application/xls', 'application/x-xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    return {
      label: 'Open',
      href: `${this?.url}`,
      isOpenVisible: imageTypes.includes(this.type)
    };
  }

  public get buttonDelete() {
    return {
      label: '',
      icon: 'lnr-cross',
      class: 'btn-sm ohq-btn-danger'
    };
  }

  get pillsVisibeMobile(): any {
    let label = 'NO';
    let className = 'ohq-badge-danger';

    if (this.isMobileVisible) {
      label = 'YES';
      className = 'ohq-badge-success';
    }
    return {
      label,
      class: className
    };
  }
}
