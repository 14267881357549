import { IEvent } from '@shared/interfaces/event.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IReport } from '@shared/interfaces/reports.interface';
import { IUser } from '@shared/interfaces/user.interface';
export class Report implements IReport {
  public id: string;
  public name: string;
  public startDate: Date;
  public endDate: Date;
  public createdAt: Date;
  public events: Array<IEvent>[];
  public file: string;
  public user: IUser;
  public parentLocations: Array<ILocation>[];
  public locations: Array<ILocation>[];
  public data?: any;
  public notes: string;

  public get buttonDownload() {
    return {
      icon: 'lnr-download me-2',
      label: 'Download',
      class: 'btn-light btn-sm'
    };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(partial: Partial<IReport>) {
    Object.assign(this, {
      ...partial
     });
  }
}
