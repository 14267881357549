import { IItem } from '@shared/interfaces/item.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IQRCode } from '@shared/interfaces/qrcode.interface';
import { ITemplate } from '@shared/interfaces/template.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { Template } from '@shared/models/template.model';
import { User } from '@shared/models/user.model';

export class QRCode implements IQRCode {
  public id: string;
  public location: Partial<ILocation>;
  public user: Partial<IUser>;
  public template: Partial<ITemplate>;
  public items: Partial<IItem[]>;
  public notifyees: Partial<IUser>[];
  parentLocation: Partial<ILocation>;

  public label: string;

  public isDailyDigestEnabled: boolean;
  public step: number;
  public isDeleted: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  constructor(partial: Partial<IQRCode>) {
    Object.assign(this, {
      ...partial,
      location:
        typeof partial?.location === 'object'
          ? new Location(partial.location)
          : partial?.location,
      notifyees:
        typeof partial?.notifyees === 'object'
          ? partial.notifyees.map((i: IUser) => new User(i))
          : [],
      template:
        typeof partial?.template === 'object'
          ? new Template(partial.template)
          : partial?.template
    });
  }

  // Manager from parent location
  public get userFullName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }

  public get locationLabel(): string {
    return this.location.label;
  }

  public get templateLabel(): string {
    return this.template.label;
  }

  public get buttonDownload() {
    return {
      icon: 'lnr-focus me-2',
      label: 'QR Code',
      class: 'btn-light btn-sm'
    };
  }

  public get linkQrCode() {
    return {
      label: 'See form',
      href: `/forms/${this.id}`
    };
  }

  public get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }

  public get pillsNotifyees(): any {
    let label = '-';
    let className = 'text-dark';

    if (this.notifyees.length === 1) {
      label = this.notifyees[0].fullName;

      className = 'ohq-badge-success';
    } else if (this.notifyees.length > 1) {
      label = `${this.notifyees.length} recipients`;
      className = 'ohq-badge-warning';
    }

    return {
      label,
      class: className
    };
  }

  public get pillsIsDailyDisgestEnabled(): any {
    let label = 'off';
    let className = 'text-dark';

    if (this.isDailyDigestEnabled) {
      label = 'on';
      className = 'ohq-badge-success';
    }

    return {
      label,
      class: className
    };
  }
}
