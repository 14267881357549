import { Directive, Input, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { IError } from '@shared/interfaces/error.interface';
import { noop, Subject } from 'rxjs';

@Directive()
export abstract class ModalDirective<T> {
  @ViewChild('entityForm', { static: false })
  public entityForm: FormGroupDirective;

  @Input() public openModal: Subject<any>;
  @Input() public msg?: any;
  @Input() public current_entity: T;
  @Input() public afterSubmit: (T) => any = noop;
  @Input() public afterClose?: () => any = noop;

  public errors: IError[] = [];
  public closeModal: Subject<any> = new Subject<any>();

  public abstract title: string;

  constructor() {
    this.whenModalClose = this.whenModalClose.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  public onDismiss() {
    this.errors = [];

    this.entityForm?.reset();
    // this.afterClose();
  }

  public resetForm() {
    this.entityForm.reset();
    this.onDismiss();
  }

  public resetModal(entity?) {
    this.resetForm();
    this.closeModal.next();

    this.afterSubmit(entity);
  }

  public whenModalClose(type?: string) {
    this.errors = [];

    if (!!this.entityForm) {
      this.entityForm.ngSubmit.emit(type);
    }
  }

  public abstract onSubmit(entity): void;

  protected abstract createForm(): void;
}
