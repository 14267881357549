import { phoneFormat } from '@shared/helpers/phone-formart.helper';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';
import { ICompany } from '@shared/interfaces/company.interface';
import { IContact } from '@shared/interfaces/contact.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IPicture } from '@shared/interfaces/picture.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { PhoneNumber } from 'libphonenumber-js';

export class Contact implements IContact {
  id: string;
  locations: Partial<ILocation>[];
  subLocation?: Partial<ILocation>;
  company: Partial<ICompany>;

  location: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: PhoneNumber;
  countryCode: string;
  role?: string;
  notes?: string;
  files?: Partial<IPicture>;
  categories?: Partial<IAssetCategory>[];
  isDeleted: boolean;
  userType?: string;
  createLogin: true;
  public user?: Partial<IUser>;

  createdAt: Date;
  updatedAt: Date;

  constructor(partial: Partial<IContact>) {
    Object.assign(this, {
      ...partial,
      company:
        partial.company === 'object'
          ? new Location(partial.company)
          : partial.company,
      locations:
        typeof partial?.locations === 'object'
          ? partial.locations.map((i: ILocation) => new Location(i))
          : [],
      subLocation:
        partial?.subLocation !== null &&
          typeof partial?.subLocation === 'object'
          ? new Location(partial.subLocation)
          : partial?.subLocation,
    });
  }

  get fullName(): string {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : '-';
  }

  get phoneNumber(): string {
    return phoneFormat(this.countryCode, this.phone);
  }

  get pillsLocation(): any {
    let label = '-';
    let className = 'text-dark';

    if (this.locations.length > 1) {
      label = `${this.locations.length} locations`;
      className = 'ohq-badge-warning';
    } else if (this.locations.length === 1) {
      label = this.locations[0].label;
      className = 'ohq-badge-success';
    }

    return {
      label,
      class: className
    };
  }
}
